import { AssignmentPriority } from '@/_shared/enums';
import { ChevronDown, ChevronUp, ChevronsUp, Minus } from 'lucide-react';
import { Path } from 'react-hook-form';
import { Form } from '../organisms/form';
import { TOption } from '../organisms/form/select/types';

export const priorityOptions: Array<TOption<AssignmentPriority | null>> = [
  { icon: <ChevronsUp className="text-red-500" />, label: 'Высочайший', value: AssignmentPriority.HIGHEST },
  { icon: <ChevronUp className="text-yellow-500" />, label: 'Высокий', value: AssignmentPriority.HIGH },
  { icon: <Minus className="text-blue-700" />, label: 'Нормальный', value: AssignmentPriority.NORMAL },
  { icon: <ChevronDown className="text-blue-500" />, label: 'Низкий', value: AssignmentPriority.LOW },
];

type TFormSelectPriorityProps<T extends Record<string, any>> = {
  className?: string;
  label?: string;
  name: Path<T>;
};

export function FormSelectPriority<T extends Record<string, any>>({
  className,
  label,
  name,
}: TFormSelectPriorityProps<T>) {
  return <Form.SelectField className={className} label={label} name={name} options={priorityOptions as any} />;
}
