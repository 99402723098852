import * as PopoverPrimitive from '@radix-ui/react-popover';
import { PopoverContent } from './popover-content';

export const Popover = {
  Anchor: PopoverPrimitive.Anchor,
  Arrow: PopoverPrimitive.Arrow,
  Close: PopoverPrimitive.Close,
  Content: PopoverContent,
  Portal: PopoverPrimitive.Portal,
  Root: PopoverPrimitive.Root,
  Trigger: PopoverPrimitive.Trigger,
};
