import {
  TApplyStockControlResultsRequest,
  TApplyStockControlResultsResponse,
  TExportStockControlReportRequest,
  TExportStockControlReportResponse,
  TFindStockControlContentsRequest,
  TFindStockControlContentsResponse,
  TImportInventoryListsRequest,
  TImportInventoryListsResponse,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export class StockControlTransport extends BaseTransport {
  async applyStockControlResults(
    request: TApplyStockControlResultsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TApplyStockControlResultsResponse> {
    return await this.post<TApplyStockControlResultsRequest, void>(
      'stock-control/apply-stock-control-results',
      request,
      options,
    );
  }

  async exportStockControlReport(
    request: TExportStockControlReportRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TExportStockControlReportResponse> {
    return await this.post<TExportStockControlReportRequest, TExportStockControlReportResponse>(
      'stock-control/export-report',
      request,
      options,
    );
  }

  async findStockControlContents(
    request: TFindStockControlContentsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindStockControlContentsResponse> {
    return await this.post<TFindStockControlContentsRequest, TFindStockControlContentsResponse>(
      'stock-control/find-contents',
      request,
      options,
    );
  }

  async importInventoryLists(
    request: TImportInventoryListsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TImportInventoryListsResponse> {
    return await this.post<TImportInventoryListsRequest, TImportInventoryListsResponse>(
      'stock-control/import-inventory-lists',
      request,
      options,
    );
  }
}

export const stockControlTransport = new StockControlTransport();
