import { TGetActionsFiltersRequest, TGetActionsFiltersResponse } from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class DashboardTransport extends BaseTransport {
  async getActionsFilters(
    request: TGetActionsFiltersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetActionsFiltersResponse> {
    return await this.post<TGetActionsFiltersRequest, TGetActionsFiltersResponse>(
      'dashboard/get-actions-filters',
      request,
      options,
    );
  }
}

export const dashboardTransport = new DashboardTransport();
