import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export type TAdminCustomerFeature = {
  category_slug: string;
  feature_id: string;
  is_available: boolean;
  is_enabled: boolean;
  is_enabled_for_new_customers: boolean;
  name: string;
  slug: string;
};
export type TAdminCustomerFeatureCategory = {
  feature_category_id: string;
  name: string;
  order: number;
  slug: string;
};

export type TGetCustomerFeaturesRequest = void;
export type TGetCustomerFeaturesResponse = {
  categories: Array<TAdminCustomerFeatureCategory>;
  features: Array<TAdminCustomerFeature>;
};

export type TUpdateCustomerFeaturesRequest = {
  features: Array<{
    feature_id: string;
    is_enabled: boolean;
  }>;
};
export type TUpdateCustomerFeaturesResponse = void;

class AdminTransport extends BaseTransport {
  async getCustomerFeatures(options?: Omit<Options, 'json'>): Promise<TGetCustomerFeaturesResponse> {
    return await this.post<TGetCustomerFeaturesRequest, TGetCustomerFeaturesResponse>(
      'admin/get-customer-features',
      undefined,
      options,
    );
  }

  async updateCustomerFeatures(
    request: TUpdateCustomerFeaturesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TUpdateCustomerFeaturesResponse> {
    return await this.post<TUpdateCustomerFeaturesRequest, TUpdateCustomerFeaturesResponse>(
      'admin/update-customer-features',
      request,
      options,
    );
  }
}

export const adminTransport = new AdminTransport();
