import { UserModel } from '@/_shared/models';
import { TBuilding, TClientUser, TFindUser, TNewlyRegisteredUser, TUser, TUserWithToken } from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

type TDeleteUserRequest = {
  user_id: string;
};
type TDeleteUserResponse = void;

type TEditUserRequest = {
  client_user_id?: string;
  first_name: string;
  last_name: string;
  role_id: string;
  working_building_id: string;
  user_id: string;
};
type TEditUserResponse = void;

export type TFindByNameRequest = {
  name_contains?: string;
  role_id?: string;
  with_roles?: boolean;
  with_permissions?: boolean;
};
type TFindByNameResponse = {
  users: Array<TFindUser>;
};

type TGetClientUsersRequest = {
  name_contains?: string;
};
type TGetClientUsersResponse = {
  client_users: Array<TClientUser>;
};

type TGetUserRelationsRequest = {
  user_id: string;
};
export type TGetUserRelationsResponse = {
  client_user?: TClientUser;
  working_location?: TBuilding;
};

type TGetTokenRequest = {
  user_id: string;
};
type TGetTokenResponse = {
  user_with_token: TUserWithToken;
};

type TGetUsersByLocationRequest = {
  location_ids?: Array<string>;
};
type TGetUsersByLocationResponse = {
  users: Array<TUser>;
};

type TRegisterUserRequest = {
  client_user_id?: string;
  first_name: string;
  last_name: string;
  role_id: string;
  working_building_id: string;
};
type TRegisterUserResponse = {
  user: TNewlyRegisteredUser;
};

type TResetPasswordRequest = {
  user_id: string;
};
type TResetPasswordResponse = {
  user: TNewlyRegisteredUser;
};

type TResetTokenRequest = {
  user_id: string;
};
type TResetTokenResponse = {
  user: TNewlyRegisteredUser;
};

export class UserTransport extends BaseTransport {
  async deleteUser(userId: string, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TDeleteUserRequest, TDeleteUserResponse>(
      'user/delete-user',
      {
        user_id: userId,
      },
      options,
    );
  }

  async getClientUsers(request: TGetClientUsersRequest, options?: Omit<Options, 'json'>): Promise<Array<TClientUser>> {
    const response = await this.post<TGetClientUsersRequest, TGetClientUsersResponse>(
      'user/get-client-users',
      request,
      options,
    );

    return response.client_users;
  }

  async getUserRelations(
    request: TGetUserRelationsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetUserRelationsResponse> {
    return await this.post<TGetUserRelationsRequest, TGetUserRelationsResponse>(
      'user/get-user-relations',
      request,
      options,
    );
  }

  async editUser(request: TEditUserRequest, options?: Omit<Options, 'json'>): Promise<TEditUserResponse> {
    await this.post<TEditUserRequest, TEditUserResponse>('user/edit', request, options);
  }

  async findByName(request: TFindByNameRequest, options?: Omit<Options, 'json'>): Promise<Array<UserModel>> {
    const response = await this.post<TFindByNameRequest, TFindByNameResponse>('user/find-by-name', request, options);

    return response.users.map((user) => new UserModel(user));
  }

  async getToken(userId: string, options?: Omit<Options, 'json'>): Promise<TUserWithToken> {
    const response = await this.post<TGetTokenRequest, TGetTokenResponse>(
      'user/get-token',
      {
        user_id: userId,
      },
      options,
    );

    return response.user_with_token;
  }

  async getUsersByLocation(
    request: TGetUsersByLocationRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<Array<UserModel>> {
    const response = await this.post<TGetUsersByLocationRequest, TGetUsersByLocationResponse>(
      'user/by-locations',
      request,
      options,
    );

    return response.users.map((user) => new UserModel(user));
  }

  async registerUser(request: TRegisterUserRequest, options?: Omit<Options, 'json'>): Promise<TNewlyRegisteredUser> {
    const { user } = await this.post<TRegisterUserRequest, TRegisterUserResponse>('user/register', request, options);

    return user;
  }

  async resetPassword(userId: string, options?: Omit<Options, 'json'>): Promise<TNewlyRegisteredUser> {
    const { user } = await this.post<TResetPasswordRequest, TResetPasswordResponse>(
      'user/reset-password',
      {
        user_id: userId,
      },
      options,
    );

    return user;
  }

  async resetToken(userId: string, options?: Omit<Options, 'json'>): Promise<TNewlyRegisteredUser> {
    const { user } = await this.post<TResetTokenRequest, TResetTokenResponse>(
      'user/reset-token',
      {
        user_id: userId,
      },
      options,
    );

    return user;
  }
}

export const userTransport = new UserTransport();
