import { AddressationStockQuantityType, StorageZoneKind } from '@/_shared/enums/storage.enums';

export const mapStorageZoneToTitle: Record<StorageZoneKind, string> = {
  [StorageZoneKind.UNIVERSAL]: 'Универсальный',
  [StorageZoneKind.CROSS_DOC]: 'Кросс-док',
  [StorageZoneKind.LONG_STORAGE]: 'Длительное хранение',
  [StorageZoneKind.PICKING_LINE]: 'Линия подбора',
  [StorageZoneKind.STORAGE_LINE]: 'Линия хранения',
  [StorageZoneKind.WAVE_CUTTER]: 'Волнорез',
  [StorageZoneKind.UNLOAD_LINE]: 'Линия разгрузки',
};

export const mapAddressationStockQuantityTypeToColor: Record<AddressationStockQuantityType, string> = {
  [AddressationStockQuantityType.POSITIVE]: 'text-green-500',
  [AddressationStockQuantityType.NEGATIVE]: 'text-red-500',
  [AddressationStockQuantityType.RESERVE]: 'text-orange-500',
};
