import z from 'zod';

export const importStockControlProductsFormSchema = z.object({
  skipProductsWithErrors: z.boolean(),
});

export type TImportInitialData = z.input<typeof importStockControlProductsFormSchema>;
export type TImportData = z.output<typeof importStockControlProductsFormSchema>;

export const initialImportFormData: TImportInitialData = {
  skipProductsWithErrors: false,
};
