import { CommandDialog } from './command-dialog';
import { CommandEmpty } from './command-empty';
import { CommandGroup } from './command-group';
import { CommandInput } from './command-input';
import { CommandItem } from './command-item';
import { CommandList } from './command-list';
import { CommandLoading } from './command-loading';
import { CommandRoot } from './command-root';
import { CommandSeparator } from './command-separator';
import { CommandShortcut } from './command-shortcut';

export const Command = {
  Dialog: CommandDialog,
  Empty: CommandEmpty,
  Group: CommandGroup,
  Input: CommandInput,
  Item: CommandItem,
  List: CommandList,
  Loading: CommandLoading,
  Root: CommandRoot,
  Separator: CommandSeparator,
  Shortcut: CommandShortcut,
};
