import { useMemo } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, Colors, ChartOptions } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Loader } from '../../atoms';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

type TDataSet<T> = {
  label: string;
  data: Array<T>;
};

type ChartPieProps<T> = {
  className?: string;
  labels: Array<string>;
  dataSets: Array<TDataSet<T>>;
  isLoading?: boolean;
  getValue?: (o: T) => number;
};

export function ChartPie<T>({ className, labels, dataSets, isLoading, getValue }: ChartPieProps<T>): JSX.Element {
  const options: ChartOptions<'pie'> = {
    plugins: { colors: { enabled: true, forceOverride: true } },
  };

  const chartData = useMemo<ChartData<'pie'>>(
    () => ({
      labels: labels,
      datasets: dataSets.map((set) => {
        return {
          label: set.label,
          data: set.data.map((o) => getValue?.(o) ?? 0),
        };
      }),
    }),
    [dataSets, getValue, labels],
  );

  return isLoading ? <Loader /> : <Pie className={className} options={options} data={chartData} />;
}
