type TEnvironment = 'development' | 'staging' | 'production';

export function getEnvironmentVariable<T = unknown>(key: string): T | null {
  return (import.meta.env[key] as T) ?? null;
}

export function getEnvironmentVariableBool(key: string): boolean {
  return getEnvironmentVariable(key) === 'true';
}

export function getEnvironment(): TEnvironment {
  return getEnvironmentVariable<TEnvironment>('MODE') ?? 'production';
}

export function isDevelopment(): boolean {
  return getEnvironment() === 'development';
}

export function isStaging(): boolean {
  return getEnvironment() === 'staging';
}

export function isProduction(): boolean {
  return getEnvironment() === 'production';
}
