import { cn } from '@/_shared/utils';
import * as RScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';

export const ScrollBar = React.forwardRef<
  React.ElementRef<typeof RScrollArea.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof RScrollArea.ScrollAreaScrollbar>
>(({ className, orientation = 'vertical', ...props }, ref) => (
  <RScrollArea.ScrollAreaScrollbar
    className={cn(
      'flex touch-none select-none transition-colors ease-in-out',
      orientation === 'vertical' && 'h-full w-2.5 border-l border-l-transparent p-[1px]',
      orientation === 'horizontal' && 'h-2.5 border-t border-t-transparent p-[1px]',
      className,
    )}
    orientation={orientation}
    ref={ref}
    {...props}
  >
    <RScrollArea.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
  </RScrollArea.ScrollAreaScrollbar>
));

ScrollBar.displayName = RScrollArea.ScrollAreaScrollbar.displayName;
