import * as z from 'zod';

export function transformFilterOptional<T>(errorMessage: string): (value: T | undefined, ctx: z.RefinementCtx) => T {
  return function (value: T | undefined, ctx: z.RefinementCtx): T {
    if (value === undefined) {
      ctx.addIssue({
        code: 'custom',
        message: errorMessage,
      });

      return z.NEVER;
    }

    return value;
  };
}

export const transformFilterUndefined = transformFilterOptional;

export function transformFilterNullable<T>(errorMessage: string): (value: T | null, ctx: z.RefinementCtx) => T {
  return function (value: T | null, ctx: z.RefinementCtx): T {
    if (value === null) {
      ctx.addIssue({
        code: 'custom',
        message: errorMessage,
      });

      return z.NEVER;
    }

    return value;
  };
}
