import { cn } from '@/_shared/utils';
import * as RSeparator from '@radix-ui/react-separator';
import * as React from 'react';

export const Separator = React.forwardRef<
  React.ElementRef<typeof RSeparator.Root>,
  React.ComponentPropsWithoutRef<typeof RSeparator.Root>
>(({ className, orientation = 'horizontal', decorative = true, ...props }, ref) => (
  <RSeparator.Root
    ref={ref}
    decorative={decorative}
    orientation={orientation}
    className={cn('shrink-0 bg-border', orientation === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]', className)}
    {...props}
  />
));
Separator.displayName = RSeparator.Root.displayName;
