import { cn } from '@/_shared/utils';
import React from 'react';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';

export const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.SubContent>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.SubContent>
>(({ className, ...props }, ref) => (
  <RDropdownMenu.SubContent
    ref={ref}
    className={cn(
      'text-on-popover z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 shadow-md animate-in data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1',
      className,
    )}
    {...props}
  />
));

DropdownMenuSubContent.displayName = RDropdownMenu.SubContent.displayName;
