import { cn } from '@/_shared/utils';
import * as RToast from '@radix-ui/react-toast';
import React from 'react';

export const ToastViewport = React.forwardRef<
  React.ElementRef<typeof RToast.Viewport>,
  React.ComponentPropsWithoutRef<typeof RToast.Viewport>
>(({ className, ...props }, ref) => (
  <RToast.Viewport
    className={cn(
      'fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]',
      className,
    )}
    ref={ref}
    {...props}
  />
));

ToastViewport.displayName = RToast.Viewport.displayName;
