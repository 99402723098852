import { cn } from '@/_shared/utils';
import * as RDialog from '@radix-ui/react-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

export const DialogDescription = forwardRef<
  ElementRef<typeof RDialog.Description>,
  ComponentPropsWithoutRef<typeof RDialog.Description>
>(({ children, className, ...props }, ref) => (
  <RDialog.Description
    asChild
    className={cn('text-sm text-slate-500', 'dark:text-slate-400', className)}
    ref={ref}
    {...props}
  >
    <div>{children}</div>
  </RDialog.Description>
));

DialogDescription.displayName = RDialog.Description.displayName;
