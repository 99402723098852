import { AssignmentPriority, AssignmentState, OrderStatus, OrderType } from '@/_shared/enums';
import { TAssignment, TBuilding, TProduct, TResponsePagination, TStorageCell, TUser } from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export type TOrderContentItem = {
  id: string;
  product: TProduct;
  quantity: number;
  storage_cell: TStorageCell;
};

export type TOrderStatusHistoryItem = {
  assigned_worker_id?: string;
  assigned_worker_name?: string;
  assignment_id: string;
  assignment_state: AssignmentState;
  assignment_type: string;
  status: OrderStatus;
  status_date: string;
};

export type TOrder = {
  assigned_user?: TUser | null;
  building?: TBuilding | null;
  can_create_dispatch_control?: boolean;
  created_at: string;
  content_items: Array<TOrderContentItem>;
  id: string;
  is_editing: boolean;
  note?: string;
  paid_at?: string;
  price_total: number;
  status: OrderStatus;
  status_history: Array<TOrderStatusHistoryItem>;
  type: OrderType;
};

export type TFindOrder = {
  assigned_user?: TUser | null;
  can_create_dispatch_control?: boolean;
  can_create_picking_control?: boolean;
  can_cancel_order?: boolean;
  created_at: string;
  id: string;
  is_editing: boolean;
  note?: string;
  paid_at?: string;
  price_total: number;
  status: OrderStatus;
  type: OrderType;
};

export type TCreateAssignmentFromOrderRequest = {
  assigned_user_id?: string;
  order_id: string;
  priority: AssignmentPriority;
  type: 'DISPATCH_CONTROL' | 'LOAD' | 'PICKING' | 'MOVE_TO_RESERVE';
};
export type TCreateAssignmentFromOrderResponse = {
  new_assignment: TAssignment;
};

export type TCreateDashboardOrderRequest = {
  building_id: string;
  note?: string;
  order_type: OrderType;
  products: Array<{
    product_code: string;
    quantity: number;
  }>;
  to_notify?: boolean;
};
export type TCreateDashboardOrderResponse = {
  new_order: TOrder;
};

export type TEditDashboardOrderRequest = {
  building_id: string;
  note?: string;
  order_id: string;
  order_type: OrderType;
  products: Array<{
    product_code: string;
    quantity: number;
  }>;
};

export type TEditDashboardOrderResponse = {
  updated_order: TOrder;
};

export type TFindOrderRequest = {
  all_locations?: boolean;
  building_id?: string;
  exclude_self?: boolean;
  from_created_at_date?: string;
  from_paid_at_date?: string;
  limit_by: number;
  page: number;
  statuses?: Array<OrderStatus>;
  to_created_at_date?: string;
  to_paid_at_date?: string;
  user_id?: string;
};

export type TFindOrderResponse = TResponsePagination<TFindOrder> & {
  orders_in_progress: number;
};

export type TGetFullRequest = {
  order_id: string;
  with_stocks?: boolean;
};

export type TGetFullResponse = {
  order: TOrder;
};

export type TCancelOrderRequest = {
  order_id: string;
};

export type TCancelOrderResponse = {
  order: TOrder;
};

export type TNotifyAboutOrderRequest = {
  order_id: string;
};

export type TNotifyAboutOrderResponse = {
  order: TOrder;
};

class OrderTransport extends BaseTransport {
  async cancelOrder(dto: TCancelOrderRequest, options?: Omit<Options, 'json'>): Promise<TCancelOrderResponse> {
    return await this.post<TCancelOrderRequest, TCancelOrderResponse>('order/cancel-dash-order', dto, options);
  }

  async createAssignmentFromOrder(
    dto: TCreateAssignmentFromOrderRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TCreateAssignmentFromOrderResponse> {
    return await this.post<TCreateAssignmentFromOrderRequest, TCreateAssignmentFromOrderResponse>(
      'order/create-assignment',
      dto,
      options,
    );
  }

  async createDashboardOrder(
    dto: TCreateDashboardOrderRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TCreateDashboardOrderResponse> {
    return await this.post<TCreateDashboardOrderRequest, TCreateDashboardOrderResponse>(
      'order/create-dashboard',
      dto,
      options,
    );
  }

  async editOrder(
    dto: TEditDashboardOrderRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TEditDashboardOrderResponse> {
    return await this.post<TEditDashboardOrderRequest, TEditDashboardOrderResponse>('order/edit', dto, options);
  }

  async findOrders(dto: TFindOrderRequest, options?: Omit<Options, 'json'>): Promise<TFindOrderResponse> {
    return await this.post<TFindOrderRequest, TFindOrderResponse>('order/find', dto, options);
  }

  async getFullOrder(dto: TGetFullRequest, options?: Omit<Options, 'json'>): Promise<TGetFullResponse> {
    return await this.post<TGetFullRequest, TGetFullResponse>('order/get-full', dto, options);
  }

  async notifyAboutOrder(
    dto: TNotifyAboutOrderRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TNotifyAboutOrderResponse> {
    return await this.post<TNotifyAboutOrderRequest, TNotifyAboutOrderResponse>(
      'order/notify-about-order',
      dto,
      options,
    );
  }
}

export const orderTransport = new OrderTransport();
