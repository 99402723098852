import {
  TFindAddressationStocksByParentRequest,
  TFindAddressationStocksByParentResponse,
  TFindAddressationStocksRequest,
  TFindAddressationStocksResponse,
  TGenerateStockReportRequest,
  TGenerateStockReportResponse,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class StorageTransport extends BaseTransport {
  async findAddressatuibStocksByParent(
    dto: TFindAddressationStocksByParentRequest,
    options?: Omit<Options, 'json'>,) {
      return await this.post<TFindAddressationStocksByParentRequest, TFindAddressationStocksByParentResponse>(
        'storage/find-addressation-stocks-by-parents',
        dto,
        options,
      );
  }

  async findAddressationStocks(
    dto: TFindAddressationStocksRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindAddressationStocksResponse> {
    return await this.post<TFindAddressationStocksRequest, TFindAddressationStocksResponse>(
      'storage/find-addressation-stocks',
      dto,
      options,
    );
  }

  async generateStockReport(
    dto: TGenerateStockReportRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGenerateStockReportResponse> {
    return await this.post<TGenerateStockReportRequest, TGenerateStockReportResponse>(
      'storage/generate-stock-report',
      dto,
      options,
    );
  }
}

export const storageTransport = new StorageTransport();
