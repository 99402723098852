import { Options } from "ky";
import { TRole, TPermission } from "../types";
import { BaseTransport } from "./base.transport";

type TGetRolesRequest = {
  with_other_permissions?: boolean;
};
type TGetRolesResponse = {
  roles: Array<TRole>;
};

type TGetRoleRequest = {
  role_id: string;
  with_other_permissions?: boolean;
};
type TGetRoleResponse = {
  role: TRole;
};

type TUpdateRoleRequest = {
  access_level: number;
  role_name: string;
  new_permission_ids?: string[];
};

type TUpdateRoleResponse = {
  role: TRole;
};

class RoleTransport extends BaseTransport {

  async getRole(request: TGetRoleRequest, options?: Omit<Options, 'json'>): Promise<TRole> {
    const { role } = await this.get<TGetRoleResponse>(`role/${request.role_id}`, {
      searchParams: {
        with_other_permission: request.with_other_permissions ?? false
      },
      ...options
    });

    return role;
  }

  async getRoles(request: TGetRolesRequest, options?: Omit<Options, 'json'>): Promise<Array<TRole>> {
    const { roles } = await this.get<TGetRolesResponse>('role', {
      searchParams: {
        with_other_permission: request.with_other_permissions ?? false
      },
      ...options
    });

    return roles.sort((a, b) => a.display_name.toLocaleLowerCase().localeCompare(b.display_name.toLocaleLowerCase()));
  }

  async updateRole(roleId: string, request: TUpdateRoleRequest, options?: Omit<Options, 'json'>): Promise<TRole> {
    const { role } = await this.put<TUpdateRoleRequest, TUpdateRoleResponse>(`role/${roleId}`, request, options);

    return role;
  }

  async createRole(request: TUpdateRoleRequest, options?: Omit<Options, 'json'>): Promise<TRole> {
    const { role } = await this.post<TUpdateRoleRequest, TUpdateRoleResponse>(`role`, request, options);

    return role
  }

  async deleteRole(roleId: string, options?: Omit<Options, 'json'>): Promise<boolean> {
    await this.delete(`role/${roleId}`, options);

    return true
  }
}

export const roleTransport = new RoleTransport();
