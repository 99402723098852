import { FormField } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { FocusEventHandler, ReactNode, useContext, useMemo } from 'react';
import { FieldPath, FieldPathValue, FieldValues, PathValue, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use-event-hook';
import { Combobox, TComboboxOption } from '../../combobox';
import { FormDisabledContext } from '../context';
import { TSelectFieldOption } from './types';

function formFullValueToOption<T extends FieldValues, K extends FieldPath<T>>(
  fullValue: PathValue<T, K>,
  labelKey: string,
  valueKey: string,
): TComboboxOption<PathValue<T, K>> {
  return {
    label: fullValue[labelKey],
    value: fullValue[valueKey],
  };
}

export type TFormComboboxFieldProps<
  T extends FieldValues,
  K extends FieldPath<T>,
  O extends TSelectFieldOption<T, K, LK, VK>,
  LK extends string = 'label',
  VK extends string = 'value',
> = {
  allowResetting?: boolean;
  allowUnregister?: boolean;
  buttonClassName?: string;
  className?: string;
  customLabelRenderer?: (option: TComboboxOption<VK>) => ReactNode;
  defaultValue?: FieldPathValue<T, K>;
  disabled?: boolean;
  emptyPlaceholder?: string;
  isLoading?: boolean;
  label?: string;
  labelKey?: LK;
  name: K;
  onFirstOpen?: VoidFunction;
  onInputChange?: (value: string) => void;
  onSelect?: (selectedOption: O | null) => void;
  options: Array<O>;
  placeholder?: string;
  saveAsOption?: boolean;
  valueKey?: VK;
  withoutInput?: boolean;
};

export function FormComboboxField<
  T extends FieldValues,
  K extends FieldPath<T>,
  O extends TSelectFieldOption<T, K, LK, VK>,
  LK extends string = 'label',
  VK extends string = 'value',
>({
  allowResetting,
  allowUnregister,
  buttonClassName,
  className,
  customLabelRenderer,
  defaultValue,
  disabled,
  emptyPlaceholder,
  isLoading,
  label,
  labelKey,
  name,
  onFirstOpen,
  onInputChange,
  onSelect,
  options,
  placeholder,
  saveAsOption,
  valueKey,
  withoutInput,
}: TFormComboboxFieldProps<T, K, O, LK, VK>): JSX.Element {
  const { t } = useTranslation('common');
  const disabledContext = useContext(FormDisabledContext);
  const { field, fieldState, formState } = useController<T, K>({
    disabled,
    name,
    defaultValue,
    shouldUnregister: allowUnregister,
  });
  const errorMessage = fieldState.error?.message;
  const shouldErrorBeShown = formState.isSubmitted || fieldState.isDirty;
  const isFieldDisabled = field.disabled || formState.isSubmitting || formState.isLoading || !!disabledContext;

  const labelKeyGuaranteed = (labelKey ?? 'label') as LK;
  const valueKeyGuaranteed = (valueKey ?? 'value') as VK;

  const clearOption = useMemo(
    () =>
      allowResetting
        ? formFullValueToOption(
            { [labelKeyGuaranteed]: t('combobox.reset'), [valueKeyGuaranteed]: undefined },
            labelKeyGuaranteed,
            valueKeyGuaranteed,
          )
        : undefined,
    [allowResetting, labelKeyGuaranteed, valueKeyGuaranteed, t],
  );

  const currentValueOption = useMemo(() => {
    return field.value ? formFullValueToOption(field.value, labelKeyGuaranteed, valueKeyGuaranteed) : undefined;
  }, [field.value, labelKeyGuaranteed, valueKeyGuaranteed]);

  const additionalOptions = useMemo(() => {
    const result = currentValueOption ? [currentValueOption] : undefined;

    if (clearOption) {
      result?.push(clearOption);
    }

    return result;
  }, [currentValueOption, clearOption]);

  const comboboxOptions = useMemo(() => {
    return options
      .map((option): TComboboxOption<VK> => formFullValueToOption(option, labelKeyGuaranteed, valueKeyGuaranteed))
      .filter((option) => !additionalOptions?.find((addOption) => addOption.value === option.value));
  }, [labelKeyGuaranteed, options, valueKeyGuaranteed, additionalOptions]);

  const handleOnComboboxBlur: FocusEventHandler<HTMLElement> = () => {
    field.onBlur();
  };

  const handleOnInputChange = (value: string) => {
    const saveValue = saveAsOption ? options.find((option) => option[valueKeyGuaranteed] === value) : value;

    onInputChange?.(saveValue as any);
  };

  const handleOnSelect = useEvent((value: string | undefined) => {
    if (value === undefined) {
      field.onChange(value as PathValue<T, K>);
      onSelect?.(value as PathValue<T, K>);

      return;
    }

    const optionItem = options.find((option: O) => option[valueKeyGuaranteed] === value);

    if (optionItem !== undefined) {
      field.onChange(optionItem);
      onSelect?.(optionItem);
    }
  });

  return (
    <FormField
      asChild
      className={cn('justify-start', className)}
      defaultValue={defaultValue}
      errorMessage={shouldErrorBeShown ? errorMessage : undefined}
      label={label}
      name={name}
      useWrapper
    >
      <Combobox
        additionalOptions={additionalOptions}
        buttonClassName={cn('bg-background w-full', buttonClassName)}
        customLabelRenderer={customLabelRenderer}
        disabled={isFieldDisabled}
        fullValue={currentValueOption}
        inputPlaceholder={emptyPlaceholder}
        isLoading={isLoading}
        placeholder={placeholder}
        onBlur={handleOnComboboxBlur}
        onFirstOpen={onFirstOpen}
        options={comboboxOptions}
        onInputChange={handleOnInputChange}
        onSelect={handleOnSelect}
        withoutInput={withoutInput}
      />
    </FormField>
  );
}
