import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Observable } from 'rxjs';

export const i18nLanguages = ['ru', 'ro'];

export const i18nLanguageChange$: Observable<string> = new Observable((observer) => {
  i18n.on('languageChanged', (lang) => {
    observer.next(lang);
  });
});

void i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    fallbackLng: 'ru',
    debug: import.meta.env.DEV,
    defaultNS: 'common',
    fallbackNS: 'common',
    ns: ['common'],

    // Prevents region-specific language codes (e.g. pt-BR) from being attempted to be loaded
    load: 'languageOnly',

    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },

    interpolation: {
      escapeValue: false, // Not needed for react
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: 'TRANSLATE_ME',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 's', 'i', 'q'],
      useSuspense: true,
    },
  });

function getResolvedI18nLanguage(): string {
  return i18n.resolvedLanguage ?? i18n.t('common:currentLanguageShort');
}

export { i18n, getResolvedI18nLanguage };
