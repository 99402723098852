import { FacetedFilter, TFacetedFilterOption } from '@/_shared/components/organisms/faceted-filter';
import { AssignmentState } from '@/_shared/enums';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use-event-hook';

const assignmentStates = [
  AssignmentState.AWAITS_SELECTION,
  AssignmentState.IN_PROGRESS,
  AssignmentState.ON_HOLD,
  AssignmentState.CLOSED,
  AssignmentState.COMPLETED,
  AssignmentState.COMPLETED_WITH_ERRORS,
];

function useAssignmentStateOptions(): Array<TFacetedFilterOption<AssignmentState>> {
  const { t } = useTranslation('warehouse_assignments');

  return assignmentStates.map((type) => ({ label: t(`assignment_state.${type}`), value: type }));
}

type TFacetedAssignmentStateFilterProps = {
  buttonClassName?: string;
  column?: Column<any, any>;
  onAssignmentStatesSelection?: (assignmentTypes: Array<TFacetedFilterOption<AssignmentState>>) => void;
  onResetFilter?: VoidFunction;
  onSelection?: (values: Array<AssignmentState>) => void;
  placeholder?: string;
  selected?: Array<AssignmentState>;
};

export function FacetedAssignmentStateFilter({
  buttonClassName,
  placeholder = 'Статус',
  onAssignmentStatesSelection,
  onResetFilter,
  onSelection,
  selected,
}: TFacetedAssignmentStateFilterProps): JSX.Element {
  const selections = useMemo(() => new Set<AssignmentState>(selected), [selected]);
  const stateOptions = useAssignmentStateOptions();

  const handleOnSelect = useEvent((values?: Array<string>) => {
    const matchingItems = new Array<TFacetedFilterOption<AssignmentState>>();

    selections.clear();

    for (const value of values ?? []) {
      const matchingItem = value ? stateOptions?.find((assignmentType) => assignmentType.value === value) : null;

      if (!matchingItem) {
        continue;
      }

      matchingItems.push(matchingItem);
      selections.add(matchingItem.value);
    }

    onSelection?.(Array.from(selections));
    onAssignmentStatesSelection?.(matchingItems);
  });

  const handleOnReset = () => {
    onResetFilter?.();
    selections.clear();
  };

  return (
    <FacetedFilter
      buttonClassName={buttonClassName}
      onResetFilter={handleOnReset}
      onSelectionUpdate={handleOnSelect}
      options={stateOptions}
      selected={selections}
      title={placeholder}
    />
  );
}
