import * as React from 'react';
import * as RToast from '@radix-ui/react-toast';
import { cn } from '@/_shared/utils';

export const ToastTitle = React.forwardRef<
  React.ElementRef<typeof RToast.Title>,
  React.ComponentPropsWithoutRef<typeof RToast.Title>
>(({ className, ...props }, ref) => (
  <RToast.Title className={cn('text-sm font-semibold', className)} ref={ref} {...props} />
));

ToastTitle.displayName = RToast.Title.displayName;
