import * as React from 'react';
import * as RToast from '@radix-ui/react-toast';
import { cn } from '@/_shared/utils';

export const ToastDescription = React.forwardRef<
  React.ElementRef<typeof RToast.Description>,
  React.ComponentPropsWithoutRef<typeof RToast.Description>
>(({ className, ...props }, ref) => (
  <RToast.Description className={cn('text-sm opacity-90', className)} ref={ref} {...props} />
));

ToastDescription.displayName = RToast.Description.displayName;
