import { addDays, parseISO } from 'date-fns';
import { formatISO } from 'date-fns/fp';
import { i18n, i18nLanguages } from './i18n';

export function getCurrentMonthStartDateTime(): Date {
  const currentDate = new Date();

  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
}

export function getCurrentMonthLastDateTime(): Date {
  const currentDate = new Date();
  const month = currentDate.getMonth();
  const year = currentDate.getFullYear();
  const lastDay = getDaysInMonth(month, year);

  return new Date(year, month, lastDay);
}

export function getDaysInMonth(m: number, y: number) {
  return m === 2 ? (y & 3 || (!(y % 25) && y & 15) ? 28 : 29) : 30 + ((m + (m >> 3)) & 1);
}

export function secondsToHms(sec: number | string) {
  const seconds = Number(sec);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  const hDisplay = h > 9 ? `${h}:` : s > 0 ? `0${h}:` : '00:';
  const mDisplay = m > 9 ? `${m}:` : s > 0 ? `0${m}:` : '00:';
  const sDisplay = s > 9 ? `${s}` : s > 0 ? `0${s}` : '00';
  return hDisplay + mDisplay + sDisplay;
}

export function tryFormatIso(date: Date | number): string;
export function tryFormatIso(date: Date | number | null | undefined): string | undefined;
export function tryFormatIso(date: Date | number | null | undefined): string | undefined {
  return date ? formatISO(date) : undefined;
}

export function tryParseIso(date: string): Date;
export function tryParseIso(date: string | null | undefined): Date | undefined;
export function tryParseIso(date: string | null | undefined): Date | undefined {
  return date ? parseISO(date) : undefined;
}

type TLanguageFormatters = {
  dateShort: Intl.DateTimeFormat;
  dateLong: Intl.DateTimeFormat;
  relative: Intl.RelativeTimeFormat;
};

const languageFormatters: Map<string, TLanguageFormatters> = new Map();

for (const language of i18nLanguages) {
  const dateFormatShort = new Intl.DateTimeFormat(language, {
    dateStyle: 'short',
    timeStyle: 'short',
  });
  const dateFormatLong = new Intl.DateTimeFormat(language, {
    dateStyle: 'long',
    timeStyle: 'short',
  });
  const relativeFormatter = new Intl.RelativeTimeFormat(language, {
    numeric: 'auto',
  });

  languageFormatters.set(language, {
    dateShort: dateFormatShort,
    dateLong: dateFormatLong,
    relative: relativeFormatter,
  });
}

export function formatDateVerboseShort(date: Date) {
  const resolvedLanguage = i18n.resolvedLanguage ?? i18n.languages.at(0) ?? navigator.language;

  return languageFormatters.get(resolvedLanguage)?.dateShort.format(date) ?? '';
}

export function formatDateVerboseLong(date: Date) {
  const resolvedLanguage = i18n.resolvedLanguage ?? i18n.languages.at(0) ?? navigator.language;

  return languageFormatters.get(resolvedLanguage)?.dateLong.format(date) ?? '';
}

const RELATIVE_FORMAT_DIVISIONS: Array<{ amount: number; name: Intl.RelativeTimeFormatUnit }> = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

export function formatRelativeToNowDate(date: Date | undefined) {
  if (!date) {
    return '';
  }

  const resolvedLanguage = i18n.resolvedLanguage ?? i18n.languages.at(0) ?? navigator.language;
  const relativeFormatter = languageFormatters.get(resolvedLanguage)?.relative;

  let duration = (date.valueOf() - new Date().valueOf()) / 1000;

  for (const division of RELATIVE_FORMAT_DIVISIONS) {
    if (!relativeFormatter) {
      break;
    }

    if (Math.abs(duration) < division.amount) {
      return relativeFormatter?.format(Math.round(duration), division.name);
    }

    duration /= division.amount;
  }

  return '';
}

export function formatFilterFromDate(date: Date | undefined): string | undefined {
  return date && !isNaN(date.valueOf()) ? tryFormatIso(date).split('T')[0] + 'T00:00:00.000Z' : undefined;
}

export function formatFilterToDate(date: Date | undefined): string | undefined {
  return date && !isNaN(date.valueOf()) ? tryFormatIso(addDays(date, 1)).split('T')[0] + 'T00:00:00.000Z' : undefined;
}
