// import { wait } from '@/_shared/utils/promises';
import { ErrorBoundary, wrapCreateBrowserRouter } from '@sentry/react';
import { Loader2 } from 'lucide-react';
import React, { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { NonIndexRouteObject, createBrowserRouter } from 'react-router-dom';

type TLoadFallback = {
  root: boolean;
};

function LoadFallback({ root }: TLoadFallback): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 py-4">
      <Loader2 className="h-8 w-8 animate-spin" />

      <p className="text-center">{t('suspense.loading', 'Загружаем...')}</p>

      {root && (
        <p className="text-center">
          <Trans i18nKey="suspense.contact_support" t={t}>
            Если страница не загружается,
            <a
              className="text-blue-500 hover:text-blue-600"
              href="https://stocrapid.md/"
              rel="noopener noreferrer"
              target="_blank"
            >
              свяжитесь с&nbsp;нами
            </a>
            .
          </Trans>
        </p>
      )}
    </div>
  );
}

export const lazyComponent = <M, K extends keyof M>(
  importFn: () => Promise<M>,
  componentName: K,
): (() => Promise<NonIndexRouteObject>) => {
  return async (): Promise<NonIndexRouteObject> => {
    const module: M = await importFn();
    const Component = module[componentName] as React.ComponentType;

    return { element: <Component /> };
  };
};

export const reactLazyComponent = <M, K extends keyof M>(
  importFn: () => Promise<M>,
  componentName: K,
  root: boolean = false,
): ReactNode => {
  const Component = React.lazy(async (): Promise<{ default: React.ComponentType }> => {
    const module = await importFn();
    const Component = module[componentName] as React.ComponentType;
    const WrappedComponent = (): JSX.Element => {
      return (
        <ErrorBoundary>
          <Component />
        </ErrorBoundary>
      );
    };

    // await wait(0);

    return { default: WrappedComponent };
  });

  // eslint-disable-next-line react/display-name
  return (
    <React.Suspense fallback={<LoadFallback root={root} />}>
      <Component />
    </React.Suspense>
  );
};

export const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
