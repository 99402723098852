import { cn } from '@/_shared/utils/classnames';
import { format } from 'date-fns';
import { CalendarDays, CalendarRange } from 'lucide-react';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { Popover } from '../../organisms/popover';
import { Button } from '../button';
import { Calendar } from './calendar';

export enum CalendarMode {
  Day = 'default',
  Range = 'range',
}

const formatDate = (date?: Date) => (date ? format(date, 'LLL dd, y') : undefined);

type TDatePickerWithRangeProps = {
  buttonClassName?: string;
  className?: string;
  disabled?: boolean;
  emptyPlaceholder?: string;
  footer?: ReactNode;
  footerReset?: boolean;
  footerResetLabel?: string;
  iconClassName?: string;
  isLoading?: boolean;
  mode?: CalendarMode;
  onRangeSelectionChange?: (dateRange: DateRange | undefined) => void;
  selectedRange?: DateRange;
};

export function DatePicker({
  buttonClassName,
  className,
  disabled,
  emptyPlaceholder,
  footer,
  footerReset,
  footerResetLabel,
  iconClassName,
  mode,
  isLoading,
  onRangeSelectionChange,
  selectedRange,
}: TDatePickerWithRangeProps) {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  const formattedRangeFrom = formatDate(selectedRange?.from);
  const formattedRangeTo = formatDate(selectedRange?.to);
  const formattedRange = formattedRangeTo
    ? `${formattedRangeFrom} - ${formattedRangeTo ?? ''}`
    : formattedRangeFrom ?? '';

  const handleOnReset = useCallback(() => onRangeSelectionChange?.(undefined), [onRangeSelectionChange]);

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  const footerNode = (
    <>
      {footer}
      {footerReset ? (
        <div className="mt-2 w-full">
          <Button className="w-full" onClick={handleOnReset} size="xs" variant="subtle">
            {footerResetLabel ?? t('date_range_picker.reset', 'Сбросить')}
          </Button>
        </div>
      ) : undefined}
    </>
  );

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover.Root onOpenChange={setIsOpen} open={isOpen}>
        <Popover.Trigger asChild>
          <Button
            disabled={disabled}
            id="date"
            isLoading={isLoading}
            variant={'outline'}
            className={cn(
              'justify-start text-left font-normal',
              !(selectedRange?.from && selectedRange?.to) && 'text-muted-foreground',
              buttonClassName,
            )}
          >
            {mode === CalendarMode.Day ? (
              <CalendarDays className={cn('mr-2 h-4 w-4', iconClassName)} />
            ) : (
              <CalendarRange className={cn('mr-2 h-4 w-4', iconClassName)} />
            )}

            {formattedRangeFrom ? (
              formattedRange
            ) : (
              <span>{emptyPlaceholder ?? t('date_range_picker.empty', 'Выберите период')}</span>
            )}
          </Button>
        </Popover.Trigger>

        <Popover.Content className="w-auto p-0" align="start">
          <Calendar
            defaultMonth={selectedRange?.from}
            footer={footerNode}
            initialFocus
            mode={mode ?? CalendarMode.Range}
            onSelect={onRangeSelectionChange}
            selected={selectedRange}
          />
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

DatePicker.displayName = 'DatePickerWithRange';
