import { cn } from '@/_shared/utils';
import { NavigationMenu } from '../../atoms';
import { TRoute } from './types';
import { useIsActiveDashboardPath } from './use-is-active-dashboard-path.hook';
import { NavLink } from 'react-router-dom';

type TNavigationItemProps<T extends TRoute> = {
  className?: string;
  forceMount?: true;
  route: T;
};

export function NavigationItem({ className, route }: TNavigationItemProps<TRoute>): JSX.Element {
  const isActive = useIsActiveDashboardPath(route.to);

  return (
    <NavigationMenu.Item asChild className={cn('list-none', className)}>
      <div>
        <NavigationMenu.Link
          active={isActive}
          asChild
          className={cn(NavigationMenu.navigationMenuTriggerStyle(), 'flex gap-2')}
        >
          {route.disabled ? (
            <span className="cursor-not-allowed select-none opacity-50">
              {route.iconComponent && <route.iconComponent className="h-6 w-6 text-inherit" />}
              {route.label}
            </span>
          ) : (
            <NavLink className="select-none" to={route.to} end>
              {route.iconComponent && <route.iconComponent className="h-6 w-6 text-inherit" />}
              {route.label}
            </NavLink>
          )}
        </NavigationMenu.Link>
      </div>
    </NavigationMenu.Item>
  );
}
