import { Button, Loader } from '@/_shared/components/atoms';
import { Command } from '@/_shared/components/organisms/command';
import { Popover } from '@/_shared/components/organisms/popover';
import { cn } from '@/_shared/utils';
import { Align, Side } from '@radix-ui/react-popper';
import { Check, ChevronsUpDown } from 'lucide-react';
import { FocusEventHandler, ReactNode, Ref, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use-event-hook';
import { ComboboxInput } from './combobox-input';

export enum ComboboxAdditionalFilterOption {
  CLEAR = 'CLEAR',
}

export const comboboxAdditionalOptions: Array<TComboboxOption<string>> = [
  {
    label: 'Сбросить',
    value: ComboboxAdditionalFilterOption.CLEAR,
  },
];

function resolveValues<TValue extends string>(
  option: TComboboxOption<TValue> | null | undefined,
  outerValue: TValue | null | undefined,
  innerValue: TValue | null | undefined,
): TValue | null | undefined {
  if (option === null) {
    return null;
  }

  if (option) {
    return option.value;
  }

  if (outerValue === null) {
    return null;
  }

  if (outerValue) {
    return outerValue;
  }

  return innerValue;
}

export type TComboboxOption<TValue extends string> = {
  label: string;
  value: TValue;
};

type TComboboxProps<TValue extends string> = {
  additionalOptions?: Array<TComboboxOption<TValue>>;
  align?: Align;
  buttonClassName?: string;
  buttonIcon?: ReactNode;
  buttonRef?: Ref<HTMLButtonElement>;
  contentClassName?: string;
  customLabelRenderer?: (option: TComboboxOption<TValue>) => ReactNode;
  disabled?: boolean;
  emptyText?: string;
  fullValue?: TComboboxOption<TValue> | null;
  inputPlaceholder?: string;
  inputValue?: string;
  isLoading?: boolean;
  onBlur?: FocusEventHandler<HTMLElement>;
  onFirstOpen?: VoidFunction;
  onInputChange?: (value: string) => void;
  onInputReset?: VoidFunction;
  onOptionSelect?: (value?: TComboboxOption<TValue>) => void;
  onSelect?: (value?: TValue) => void;
  options: Array<TComboboxOption<TValue>>;
  placeholder?: string;
  side?: Side;
  value?: TValue | null;
  withoutInput?: boolean;
};

export function Combobox<TValue extends string>({
  additionalOptions,
  align,
  buttonClassName,
  buttonIcon,
  buttonRef,
  contentClassName,
  customLabelRenderer,
  disabled,
  emptyText,
  fullValue,
  inputPlaceholder,
  inputValue,
  isLoading,
  onBlur,
  onFirstOpen,
  onInputChange,
  onInputReset,
  onOptionSelect,
  onSelect,
  options,
  placeholder,
  side,
  value: outerValue,
  withoutInput,
}: TComboboxProps<TValue>): JSX.Element {
  const { t } = useTranslation('common');
  const hasBeenOpened = useRef(false);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<TComboboxOption<TValue> | null | undefined>();

  emptyText ??= t('common:combobox.empty', 'Ничего не найдено');
  inputPlaceholder ??= t('common:combobox.input_placeholder', 'Поиск...');
  placeholder ??= t('common:combobox.placeholder', 'Выберите из списка');

  outerValue = resolveValues(fullValue, outerValue, selectedOption?.value);

  const handleOnSetOpen = useEvent((value: boolean) => {
    if (!hasBeenOpened.current && value) {
      onFirstOpen?.();
      hasBeenOpened.current = true;
    }

    setOpen(value);
  });

  const allOptions = useMemo(() => {
    if (additionalOptions && !options.length) {
      return additionalOptions;
    }

    return additionalOptions ? additionalOptions.concat(options) : options;
  }, [additionalOptions, options]);

  useEffect(() => {
    const newValue = options.find((option) => option.value === outerValue);

    if (newValue) {
      setSelectedOption(newValue);
    }
  }, [options, outerValue]);

  return (
    <Popover.Root open={open} onOpenChange={handleOnSetOpen} modal>
      <Popover.Trigger asChild ref={buttonRef}>
        <Button
          aria-expanded={open}
          childrenWrapperClassName={cn('justify-start', !outerValue && 'text-zinc-500/75')}
          className={cn('min-w-[100px] justify-between text-start', buttonClassName)}
          disabled={disabled}
          icon={buttonIcon}
          onBlur={onBlur}
          rightDecoration={
            isLoading ? (
              <Loader className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            ) : (
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            )
          }
          variant="outline"
        >
          {(outerValue ? allOptions.find((option) => option.value === outerValue)?.label : placeholder) ??
            selectedOption?.label ??
            ' '}
        </Button>
      </Popover.Trigger>

      <Popover.Content
        className={cn('h-80 w-[300px] overflow-y-hidden p-0', contentClassName)}
        align={align}
        side={side}
      >
        <Command.Root filter={() => 1} shouldFilter={false}>
          {!withoutInput && (
            <ComboboxInput
              inputPlaceholder={inputPlaceholder}
              inputValue={inputValue}
              onInputChange={onInputChange}
              onInputReset={onInputReset}
            />
          )}

          {isLoading ? (
            <Command.Loading />
          ) : (
            <>
              <Command.Empty>{emptyText}</Command.Empty>

              <div className="overflow-hidden overflow-y-auto">
                {additionalOptions && additionalOptions.length > 0 && !inputValue && (
                  <>
                    <Command.Group>
                      {additionalOptions.map((option) => (
                        <Command.Item
                          className="cursor-pointer"
                          key={option.value}
                          onSelect={(currentValue) => {
                            setSelectedOption(currentValue === selectedOption?.value ? undefined : option);

                            onOptionSelect?.(option);
                            onSelect?.(option.value);
                            setOpen(false);
                          }}
                          value={option.value}
                        >
                          <Check
                            className={cn(
                              'mr-2 h-4 w-4',
                              option.value === selectedOption?.value ? 'opacity-100' : 'opacity-0',
                            )}
                          />

                          {option.label}
                        </Command.Item>
                      ))}
                    </Command.Group>

                    <Command.Separator alwaysRender />
                  </>
                )}

                <Command.Group>
                  {options.map((option) => (
                    <Command.Item
                      className="cursor-pointer"
                      key={option.value}
                      onSelect={(currentValue) => {
                        setSelectedOption(currentValue === selectedOption?.value ? undefined : option);

                        onOptionSelect?.(option);
                        onSelect?.(option.value);
                        setOpen(false);
                      }}
                      value={option.value}
                    >
                      <Check
                        className={cn(
                          'mr-2 h-4 w-4',
                          option.value === selectedOption?.value ? 'opacity-100' : 'opacity-0',
                        )}
                        tabIndex={-1}
                      />

                      {customLabelRenderer ? customLabelRenderer(option) : option.label}
                    </Command.Item>
                  ))}
                </Command.Group>
              </div>
            </>
          )}
        </Command.Root>
      </Popover.Content>
    </Popover.Root>
  );
}
