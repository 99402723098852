import { FormField, Input } from '@/_shared/components/atoms';
import get from 'lodash.get';
import { ChangeEventHandler, FocusEventHandler, useContext } from 'react';
import { Path, useController } from 'react-hook-form';
import { InputAttributes, PatternFormat } from 'react-number-format';
import { PatternFormatProps } from 'react-number-format/types/types';
import { FormDisabledContext } from './context';

type TFormPatternFieldProps<T extends Record<string, any>> = {
  allowUnregister?: boolean;
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  inputMode?: InputAttributes['inputMode'];
  label?: string;
  name: Path<T>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
} & PatternFormatProps;

export function FormPatternField<T extends Record<string, any>>({
  allowUnregister,
  className,
  disabled,
  inputClassName,
  inputMode = 'numeric',
  label,
  name,
  onBlur,
  onChange,
  ...patternFormatProps
}: TFormPatternFieldProps<T>) {
  const disabledContext = useContext(FormDisabledContext);
  const { field, formState } = useController({ name, shouldUnregister: allowUnregister });
  const isInputDisabled = disabled || formState.isSubmitting || formState.isLoading || !!disabledContext;
  const fieldError = get(formState.errors, name);
  const errorMessage = fieldError?.message as string | undefined;

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    field.onBlur();

    onBlur?.(event);
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    field.onChange(event);

    onChange?.(event);
  };

  return (
    <FormField className={className} errorMessage={errorMessage} name={name} label={label} useWrapper>
      <PatternFormat
        customInput={Input}
        className={inputClassName}
        disabled={isInputDisabled}
        getInputRef={field.ref}
        inputMode={inputMode}
        name={field.name}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        value={field.value}
        {...patternFormatProps}
      />
    </FormField>
  );
}

FormPatternField.displayName = 'FormNumericField';
