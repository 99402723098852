import { FilterService } from '@/_shared/services';

const initialGlobalFilterState = {};

export class GlobalFilterService extends FilterService<Record<string, any>> {
  constructor() {
    super(initialGlobalFilterState);
  }
}

export const globalFilterService = new GlobalFilterService();
