import { cn } from '@/_shared/utils';
import { Slot } from '@radix-ui/react-slot';
import { VariantProps, cva } from 'class-variance-authority';
import { Loader2 } from 'lucide-react';
import { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';

export const buttonVariants = cva(
  'inline-flex overflow-hidden select-none items-center justify-center gap-2 rounded-md text-sm font-medium whitespace-nowrap transition-colors focus:outline-none focus:ring-ring focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 dark:hover:bg-slate-800 dark:hover:text-slate-100 disabled:opacity-50 dark:focus:ring-slate-400 disabled:pointer-events-none dark:focus:ring-offset-slate-900 data-[state=open]:bg-slate-100 dark:data-[state=open]:bg-slate-800',
  {
    variants: {
      direction: {
        horizontal: 'flex-row',
        vertical: 'flex-col',
      },
      isDisabled: {
        false: 'pointer-events-auto cursor-pointer',
        true: 'text-gray-500 disabled:pointer-events-none disabled:cursor-disabled',
      },
      isLoading: {
        false: 'cursor-pointer',
        true: 'disabled:pointer-events-auto disabled:cursor-wait',
      },
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-2 rounded-md',
        lg: 'h-11 px-8 rounded-md',
        xs: 'h-8 px-2 rounded-md',

        icon: 'h-8 w-8 px-2 rounded-md',
        icon_xs: 'h-6 w-6 px-1 rounded-md',
        icon_sm: 'h-9 w-9 px-2 rounded-md',
        icon_md: 'h-10 w-10 px-2 rounded-md',
        icon_lg: 'h-11 w-11 px-2 rounded-md',
      },
      variant: {
        default: 'bg-slate-900 text-white hover:bg-slate-700 dark:bg-slate-50 dark:text-slate-900',
        destructive: 'bg-red-500 text-white hover:bg-red-600 dark:hover:bg-red-600',
        warning: 'bg-orange-600 text-white hover:bg-orange-700 dark:hover:bg-orange-700',
        outline: 'bg-transparent border border-slate-200 hover:bg-slate-100 dark:border-slate-700 dark:text-slate-100',
        subtle: 'bg-slate-100 text-slate-900 hover:bg-slate-200 dark:bg-slate-700 dark:text-slate-100',
        ghost:
          'bg-transparent hover:bg-slate-100 dark:hover:bg-slate-800 dark:text-slate-100 dark:hover:text-slate-100 data-[state=open]:bg-transparent dark:data-[state=open]:bg-transparent',
        link: 'bg-transparent dark:bg-transparent underline-offset-4 hover:underline text-slate-900 dark:text-slate-100 hover:bg-transparent dark:hover:bg-transparent',
      },
    },
    compoundVariants: [
      {
        className: 'h-auto w-20',
        direction: 'vertical',
      },
    ],
    defaultVariants: {
      direction: 'horizontal',
      isLoading: false,
      size: 'default',
      variant: 'default',
    },
  },
);

type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    childrenWrapperClassName?: string;
    icon?: ReactNode;
    iconClassName?: string;
    isLoading?: boolean;
    loaderClassName?: string;
    rightDecoration?: ReactNode;
    rightDecorationClassName?: string;
  };

export const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      asChild,
      children,
      childrenWrapperClassName,
      className,
      direction,
      disabled,
      icon,
      iconClassName,
      isLoading,
      loaderClassName,
      rightDecoration,
      rightDecorationClassName,
      size,
      type = 'button',
      variant,
      ...props
    },
    ref,
  ) => {
    const buttonClassName = cn(
      buttonVariants({ direction, isDisabled: disabled, isLoading, size, variant }),
      className,
    );
    const iconWrapperClassName = cn('h-4 w-4', iconClassName);
    const loaderWrapperClassName = cn('h-4 w-4 animate-spin', loaderClassName);

    return asChild ? (
      <Slot className={buttonClassName} ref={ref} {...props}>
        {children}
      </Slot>
    ) : (
      <button className={buttonClassName} disabled={disabled || isLoading} ref={ref} type={type} {...props}>
        {!asChild && isLoading ? <Loader2 className={loaderWrapperClassName} /> : null}

        {!asChild && !isLoading && icon ? <Slot className={iconWrapperClassName}>{icon}</Slot> : null}

        {!!children && (
          <span className={cn('flex flex-1 justify-center truncate', childrenWrapperClassName)}>{children}</span>
        )}

        {!asChild && rightDecoration ? <Slot className={rightDecorationClassName}>{rightDecoration}</Slot> : null}
      </button>
    );
  },
);

Button.displayName = 'Button';
