import { cn } from '@/_shared/utils';
import { Loader2 } from 'lucide-react';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

type TLoaderProps = {
  spinnerClassName?: string;
} & ComponentPropsWithoutRef<'div'>;

export const Loader = forwardRef<HTMLDivElement, TLoaderProps>(function Loader(
  { className, spinnerClassName }: TLoaderProps,
  ref,
): JSX.Element {
  return (
    <div className={cn('flex h-full w-full items-center justify-center', className)} ref={ref}>
      <Loader2 className={cn('h-8 w-8 animate-spin', spinnerClassName)} />
    </div>
  );
});
