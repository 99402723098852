import { cn } from '@/_shared/utils';
import * as RSelect from '@radix-ui/react-select';
import { Check } from 'lucide-react';
import React from 'react';

type TSelectItemProps = React.ComponentPropsWithoutRef<typeof RSelect.Item> & {
  icon?: JSX.Element;
};

export const SelectItem = React.forwardRef<React.ElementRef<typeof RSelect.Item>, TSelectItemProps>(
  ({ className, children, icon, ...props }, ref) => (
    <RSelect.Item
      className={cn(
        'relative flex w-full cursor-pointer select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        className,
      )}
      ref={ref}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <RSelect.ItemIndicator>
          <Check className="h-4 w-4" />
        </RSelect.ItemIndicator>
      </span>

      <RSelect.ItemText asChild className="flex-1">
        <div className="flex w-full items-center gap-2">
          {icon && <span className="h-6 w-6">{icon}</span>}

          {children}
        </div>
      </RSelect.ItemText>
    </RSelect.Item>
  ),
);

SelectItem.displayName = RSelect.Item.displayName;
