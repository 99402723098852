import { cn } from '@/_shared/utils';
import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';

export const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof RNavigationMenu.Indicator>,
  React.ComponentPropsWithoutRef<typeof RNavigationMenu.Indicator>
>(({ className, ...props }, ref) => (
  <RNavigationMenu.Indicator
    className={cn(
      'top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in',
      className,
    )}
    ref={ref}
    {...props}
  >
    <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-slate-200 shadow-md dark:bg-slate-800" />
  </RNavigationMenu.Indicator>
));

NavigationMenuIndicator.displayName = RNavigationMenu.Indicator.displayName;
