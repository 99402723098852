import { cn } from '@/_shared/utils';
import React from 'react';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';

export const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.Item>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <RDropdownMenu.Item
    ref={ref}
    className={cn(
      'relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      className,
    )}
    {...props}
  />
));

DropdownMenuItem.displayName = RDropdownMenu.Item.displayName;
