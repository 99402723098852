class ModifierKeyService {
  private _isShiftPressed = false;
  private _isCtrlPressed = false;
  private _isAltPressed = false;

  get isShiftPressed() {
    return this._isShiftPressed;
  }

  get isCtrlPressed() {
    return this._isCtrlPressed;
  }

  get isAltPressed() {
    return this._isAltPressed;
  }

  constructor() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Shift') {
        this._isShiftPressed = true;
      }
      if (event.key === 'Control') {
        this._isCtrlPressed = true;
      }
      if (event.key === 'Alt') {
        this._isAltPressed = true;
      }
    });

    document.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') {
        this._isShiftPressed = false;
      }
      if (event.key === 'Control') {
        this._isCtrlPressed = false;
      }
      if (event.key === 'Alt') {
        this._isAltPressed = false;
      }
    });

    document.addEventListener(
      'visibilitychange',
      (_event) => {
        if (document.hidden) {
          this._isShiftPressed = false;
          this._isCtrlPressed = false;
          this._isAltPressed = false;

          return;
        }
      },
      false,
    );
  }
}

export const modifierKeyService = new ModifierKeyService();
