import { cn } from '@/_shared/utils';
import * as RSelect from '@radix-ui/react-select';
import React from 'react';

export const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof RSelect.Separator>,
  React.ComponentPropsWithoutRef<typeof RSelect.Separator>
>(({ className, ...props }, ref) => (
  <RSelect.Separator className={cn('-mx-1 my-1 h-px bg-muted', className)} ref={ref} {...props} />
));

SelectSeparator.displayName = RSelect.Separator.displayName;
