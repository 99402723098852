import { cn } from '@/_shared/utils';
import * as RToast from '@radix-ui/react-toast';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

const toastVariants = cva(
  'data-[swipe=move]:transition-none grow-1 group relative pointer-events-auto flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full mt-4 data-[state=closed]:slide-out-to-right-full dark:border-slate-700 last:mt-0 sm:last:mt-4',
  {
    variants: {
      variant: {
        default: 'bg-white border-slate-200 dark:bg-slate-800 dark:border-slate-700',
        destructive: 'group destructive bg-red-600 text-white border-red-600 dark:border-red-600',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export const ToastRoot = React.forwardRef<
  React.ElementRef<typeof RToast.Root>,
  React.ComponentPropsWithoutRef<typeof RToast.Root> & VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return <RToast.Root className={cn(toastVariants({ variant }), className)} ref={ref} {...props} />;
});

ToastRoot.displayName = RToast.Root.displayName;
