import { cn } from '@/_shared/utils';
import * as RSelect from '@radix-ui/react-select';
import React from 'react';

export const SelectLabel = React.forwardRef<
  React.ElementRef<typeof RSelect.Label>,
  React.ComponentPropsWithoutRef<typeof RSelect.Label>
>(({ className, ...props }, ref) => (
  <RSelect.Label className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} ref={ref} {...props} />
));

SelectLabel.displayName = RSelect.Label.displayName;
