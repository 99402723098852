import { TComboboxOption } from '@/_shared/components/organisms/combobox';
import { ExportDocumentType, StockControlReportType } from '@/_shared/enums';
import { transformFilterNullable } from '@/_shared/utils';
import z from 'zod';
import { ExportColumnKey, exportColumns } from './consts';

export const exportStockControlReportFormSchema = z.object({
  columns: z.custom<Partial<Record<ExportColumnKey, boolean>>>(),
  documentType: z
    .custom<TComboboxOption<ExportDocumentType>>()
    .nullable()
    .transform(transformFilterNullable('warehouse_stock_control:export_choose_type')),
  file: z
    .custom<TComboboxOption<string>>()
    .nullable()
    .transform(transformFilterNullable('warehouse_stock_control:export_choose_file')),
  exportType: z
    .custom<TComboboxOption<StockControlReportType>>()
    .nullable()
    .transform(transformFilterNullable('warehouse_stock_control:export_choose_type_s')),
  language: z
    .custom<TComboboxOption<string>>()
    .nullable()
    .transform(transformFilterNullable('warehouse_stock_control:export_choose_language')),
  withCells: z.boolean(),
});

export type TExportFormInitialData = z.input<typeof exportStockControlReportFormSchema>;
export type TExportFormData = z.output<typeof exportStockControlReportFormSchema>;

export const initialExportFormData: Partial<TExportFormInitialData> = {
  columns: exportColumns.reduce((acc, column) => {
    return Object.assign(acc, { [column.id]: true });
  }, {}),
  documentType: null,
  file: null,
  exportType: null,
  language: {
    label: 'Română',
    value: 'ro',
  },
  withCells: false,
};
