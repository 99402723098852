import { useMemo } from 'react';
import { CustomerFeature } from '../enums';
import { useCustomerInfo } from './use-customer-info';

export function useAvailableCustomerFeatures(expectedFeatures: Array<CustomerFeature>): Array<CustomerFeature> {
  const { data: customerInfo } = useCustomerInfo();

  return useMemo(() => {
    return customerInfo ? expectedFeatures.filter((feature) => customerInfo?.features.includes(feature)) : [];
  }, [customerInfo, expectedFeatures]);
}

export function useCustomerFeatures(feature: CustomerFeature | Array<CustomerFeature>): boolean {
  const features = useMemo(() => (Array.isArray(feature) ? feature : [feature]), [feature]);

  return useAvailableCustomerFeatures(features).length === features.length;
}
