import { cn } from '@/_shared/utils';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef, useId } from 'react';
import { NavLink } from 'react-router-dom';
import { NavigationMenu } from '../../atoms';
import { NavigationItem } from './navigation-item';
import { TRoute, TRouteWithChildren, TRouteWithChildrenItem } from './types';
import { useIsActiveDashboardPath } from './use-is-active-dashboard-path.hook';

type TNavigationItemProps<T extends TRoute> = {
  className?: string;
  forceMount?: true;
  route: T;
  onNodeUpdate: (trigger: HTMLElement | null, itemValue: string) => void;
};

export function NavigationItemWithChildren({
  className,
  forceMount,
  route,
  onNodeUpdate,
}: TNavigationItemProps<TRouteWithChildren>): JSX.Element {
  const id = useId();
  const isActive = useIsActiveDashboardPath(route.to);

  if (route.children?.length === 1 && route.children[0]) {
    return (
      <NavigationItem key={route.children[0].to} route={route.children[0]} />
    );
  }

  return (
    <NavigationMenu.Item value={id} className={cn('list-none', className)} data-active={isActive}>
      <NavigationMenu.Trigger
        className={cn(NavigationMenu.navigationMenuTriggerStyle(), 'flex gap-2')}
        data-active={isActive || undefined}
        ref={(node) => onNodeUpdate(node, id)}
      >
        {route.iconComponent && <route.iconComponent className="h-6 w-6 text-inherit" />}
        {route.label}
      </NavigationMenu.Trigger>

      <NavigationMenu.Content forceMount={forceMount}>
        <ul
          className={cn('grid min-w-48 grid-cols-[repeat(2,_1fr)] items-stretch gap-1 p-2', route.containerClassName)}
        >
          {route.children.map((childRoute) => (
            <ListItem className="w-full" key={childRoute.to} route={childRoute} title={childRoute.label}>
              {childRoute.description}
            </ListItem>
          ))}
        </ul>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
}

const ListItem = forwardRef<
  ElementRef<'a'>,
  ComponentPropsWithoutRef<'a'> & {
    route: TRouteWithChildrenItem;
  }
>(({ className, title, children, route, ...props }, ref) => {
  const isActive = useIsActiveDashboardPath(route.to);

  return (
    <li>
      <NavigationMenu.Link active={isActive} asChild>
        <NavLink
          className={cn(
            NavigationMenu.navigationMenuTriggerStyle(),
            'flex h-full w-full select-none gap-2 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent focus:bg-accent',
            className,
          )}
          ref={ref}
          to={route.to}
          {...props}
        >
          <div className="flex flex-col justify-center">
            {route.iconComponent && <route.iconComponent className="h-6 w-6 text-inherit" />}
          </div>

          <div className="flex flex-1 flex-col justify-center gap-1">
            <div className="text-sm leading-none">{title}</div>
            <p className={cn('line-clamp-2 text-xs leading-snug opacity-70', isActive && 'text-white opacity-80')}>
              {children}
            </p>
          </div>
        </NavLink>
      </NavigationMenu.Link>
    </li>
  );
});
ListItem.displayName = 'ListItem';
