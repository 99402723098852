import { FormCheckboxField } from './form-checkbox-field';
import { FormFileField } from './form-file-field';
import { FormNumericField } from './form-numeric-field';
import { FormPatternField } from './form-pattern-field';
import { FormRoot } from './form-root';
import { FormTextField } from './form-text-field';
import { FormTextareaField } from './form-textarea-field';
import { FormComboboxField } from './select/form-combobox-field';
import { FormSelectField } from './select/form-select-field';

export const Form = {
  CheckboxField: FormCheckboxField,
  ComboboxField: FormComboboxField,
  FileField: FormFileField,
  NumericField: FormNumericField,
  PatternField: FormPatternField,
  Root: FormRoot,
  SelectField: FormSelectField,
  TextField: FormTextField,
  TextareaField: FormTextareaField,
};
