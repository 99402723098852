import { cn } from '@/_shared/utils';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';

export const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.Separator>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.Separator>
>(({ className, ...props }, ref) => (
  <RDropdownMenu.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} />
));

DropdownMenuSeparator.displayName = RDropdownMenu.Separator.displayName;
