import { isValid, parseJSON } from 'date-fns';

// TODO: replace with proper serialization library, will pray it doesn't randomly decide to parse unrelated string as a valid date
function jsonReviver(_key: string, value: any) {
  if (typeof value === 'string') {
    try {
      const parsedDate = parseJSON(value);

      if (isValid(parsedDate)) {
        return parsedDate;
      }
    } catch (_error) {
      return value;
    }
  }

  return value;
}

export function stringifyJson(value: any): string {
  return JSON.stringify(value);
}

export function parseJson<T>(json: string): T | null {
  return JSON.parse(json, jsonReviver);
}
