import { UserModel } from '@/_shared/models';
import { BehaviorSubject, Observable } from 'rxjs';

class UserService {
  private userSubject: BehaviorSubject<UserModel | null>;
  public user$: Observable<UserModel | null>;

  constructor() {
    this.userSubject = new BehaviorSubject<UserModel | null>(null);
    this.user$ = this.userSubject.asObservable();
  }

  getCurrentUser(): UserModel | null {
    return this.userSubject.getValue();
  }

  setUser(user: UserModel | null): void {
    this.userSubject.next(user);
  }
}

export const userService = new UserService();
