import { Button, SimpleTooltip } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { FilterX } from 'lucide-react';
import { ElementRef, forwardRef } from 'react';

export const resetFiltersButtonVariants = cva('', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'h-10 w-10 p-2 rounded-md',
      sm: 'h-9 w-9 px-2 p-2 rounded-md',
      lg: 'h-11 h-11 px-8 p-2 rounded-md',
      xs: 'h-8 w-8 px-2 p-2 rounded-md',
      icon: 'h-8 w-8 px-2 p-2 rounded-md',
    },
  },
});

type TResetFiltersButtonProps = {
  className?: string;
  onClick: VoidFunction;
} & VariantProps<typeof resetFiltersButtonVariants>;

export const ResetFiltersButton = forwardRef<ElementRef<typeof Button>, TResetFiltersButtonProps>(
  ({ className, onClick, size }: TResetFiltersButtonProps, ref): JSX.Element => {
    const tourButtonClassName = cn(resetFiltersButtonVariants({ size }), className);

    return (
      <SimpleTooltip content="Сбросить фильтры">
        <Button
          className={tourButtonClassName}
          icon={<FilterX />}
          onClick={onClick}
          ref={ref}
          size={size}
          variant="subtle"
        />
      </SimpleTooltip>
    );
  },
);
ResetFiltersButton.displayName = 'ResetFiltersButton';
