import { Align, Side } from '@radix-ui/react-popper';
import * as RTooltip from '@radix-ui/react-tooltip';
import React from 'react';
import { TooltipContent } from './tooltip-content';

export const Tooltip = {
  Content: TooltipContent,
  Portal: RTooltip.Portal,
  Provider: RTooltip.Provider,
  Root: RTooltip.Root,
  Trigger: RTooltip.Trigger,
};

type TSimpleTooltipProps = {
  align?: Align;
  children: React.ReactNode;
  content: React.ReactNode;
  side?: Side;
};

export function SimpleTooltip({ align, children, content, side }: TSimpleTooltipProps): JSX.Element {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content align={align} side={side}>
            {content}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
