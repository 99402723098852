import {
  TFindFilesRequest,
  TFindFilesResponse,
  TGetDownloadUrlRequest,
  TGetDownloadUrlResponse,
  TUploadFilesRequest,
  TUploadFilesResponse,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class UploadTransport extends BaseTransport {
  async findFiles(request: TFindFilesRequest, options?: Omit<Options, 'json'>) {
    return await this.post<TFindFilesRequest, TFindFilesResponse>('upload/find-files', request, options);
  }

  async getDownloadUrl(request: TGetDownloadUrlRequest, options?: Omit<Options, 'json'>) {
    return await this.post<TGetDownloadUrlRequest, TGetDownloadUrlResponse>(
      'upload/get-download-url',
      request,
      options,
    );
  }

  async uploadFiles(
    formData: FormData,
    searchParams: TUploadFilesRequest,
    options?: Omit<Options, 'json' | 'searchParams'>,
  ) {
    return await this.postFormData<TUploadFilesResponse>(
      'upload/upload-files',
      formData,
      Object.assign(options ?? {}, { searchParams }),
    );
  }
}

export const uploadTransport = new UploadTransport();
