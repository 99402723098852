import { StorageAddressingElementType } from '@/_shared/enums';
import {
  TBuilding,
  TCreateBuildingRequest,
  TCreateLoadingLinesRequest,
  TCreateLoadingLinesResponse,
  TCreateStorageCellRequest,
  TCreateStorageCellResponse,
  TCreateStorageSubZoneRequest,
  TCreateStorageSubZoneResponse,
  TCreateStorageZoneRequest,
  TCreateStorageZoneResponse,
  TDeleteAddressationElementsRequest,
  TDeleteAddressationElementsResponse,
  TDeleteBuildingsRequest,
  TDeleteStorageCellsRequest,
  TDeleteStorageCellsResponse,
  TDeleteStorageSubZonesRequest,
  TDeleteStorageSubZonesResponse,
  TDeleteStorageZonesRequest,
  TDeleteStorageZonesResponse,
  TEditBuildingRequest,
  TEditBuildingResponse,
  TEditCellRequest,
  TEditCellResponse,
  TEditRackRequest,
  TEditRackResponse,
  TEditSectionRequest,
  TEditSectionResponse,
  TFindBuildingsRequest,
  TFindBuildingsResponse,
  TFindClientAreasRequest,
  TFindClientAreasResponse,
  TFindLoadingLinesRequest,
  TFindLoadingLinesResponse,
  TFindStorageCellsRequest,
  TFindStorageCellsResponse,
  TFindStorageSubZonesRequest,
  TFindStorageSubZonesResponse,
  TFindStorageZonesRequest,
  TFindStorageZonesResponse,
  TGetAddressationElementByIdRequest,
  TGetAddressationElementByIdResponse,
  TGetAddressationTypesRequest,
  TGetAddressationTypesResponse,
  TGetBuildingRelationsRequest,
  TGetBuildingRelationsResponse,
  TGetCellBarcodeRequest,
  TGetCellBarcodeResponse,
  TGetCellRelationsRequest,
  TGetCellRelationsResponse,
  TGetRackRelationsRequest,
  TGetRackRelationsResponse,
  TGetSectionRelationsRequest,
  TGetSectionRelationsResponse,
  TSelectAddressingElementsRequest,
  TSelectAddressingElementsResponse,
  TStorageCell,
  TStorageSubZone,
  TStorageZone,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class StorageAddressationTransport extends BaseTransport {
  async createBuilding(request: TCreateBuildingRequest, options?: Omit<Options, 'json'>): Promise<void> {
    return await this.post<TCreateBuildingRequest, void>('storage/addressation/create-building', request, options);
  }

  async createLoadingLines(
    dto: TCreateLoadingLinesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<Array<TStorageCell>> {
    const response = await this.post<TCreateLoadingLinesRequest, TCreateLoadingLinesResponse>(
      'storage/addressation/create-loading-lines',
      dto,
      options,
    );

    return response.new_loading_lines;
  }

  async createStorageCell(
    dto: TCreateStorageCellRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<Array<TStorageCell>> {
    const response = await this.post<TCreateStorageCellRequest, TCreateStorageCellResponse>(
      'storage/addressation/create-cells',
      dto,
      options,
    );

    return response.new_storage_cells;
  }

  async createStorageSubZone(
    dto: TCreateStorageSubZoneRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<Array<TStorageSubZone>> {
    const response = await this.post<TCreateStorageSubZoneRequest, TCreateStorageSubZoneResponse>(
      'storage/addressation/create-racks',
      dto,
      options,
    );

    return response.new_storage_sub_zones;
  }

  async createStorageZone(
    dto: TCreateStorageZoneRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<Array<TStorageZone>> {
    const response = await this.post<TCreateStorageZoneRequest, TCreateStorageZoneResponse>(
      'storage/addressation/create-sections',
      dto,
      options,
    );

    return response.new_storage_zones;
  }

  async deleteBuilding(request: TDeleteBuildingsRequest, options?: Omit<Options, 'json'>): Promise<void> {
    return await this.post<TDeleteBuildingsRequest, void>('storage/addressation/delete-buildings', request, options);
  }

  async deleteElements(dto: TDeleteAddressationElementsRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TDeleteAddressationElementsRequest, TDeleteAddressationElementsResponse>(
      'storage/addressation/delete',
      dto,
      options,
    );
  }

  async deleteStorageCells(dto: TDeleteStorageCellsRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TDeleteStorageCellsRequest, TDeleteStorageCellsResponse>(
      'storage/addressation/delete-cells',
      dto,
      options,
    );
  }

  async deleteStorageSubZones(dto: TDeleteStorageSubZonesRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TDeleteStorageSubZonesRequest, TDeleteStorageSubZonesResponse>(
      'storage/addressation/delete-racks',
      dto,
      options,
    );
  }

  async deleteStorageZones(dto: TDeleteStorageZonesRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TDeleteStorageZonesRequest, TDeleteStorageZonesResponse>(
      'storage/addressation/delete-sections',
      dto,
      options,
    );
  }

  async editBuilding(dto: TEditBuildingRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TEditBuildingRequest, TEditBuildingResponse>('storage/addressation/edit-building', dto, options);
  }

  async editSection(dto: TEditSectionRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TEditSectionRequest, TEditSectionResponse>('storage/addressation/edit-section', dto, options);
  }

  async editRack(dto: TEditRackRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TEditRackRequest, TEditRackResponse>('storage/addressation/edit-rack', dto, options);
  }

  async editCell(dto: TEditCellRequest, options?: Omit<Options, 'json'>): Promise<void> {
    await this.post<TEditCellRequest, TEditCellResponse>('storage/addressation/edit-cell', dto, options);
  }

  async findBuildings(
    request: TFindBuildingsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindBuildingsResponse> {
    return await this.post<TFindBuildingsRequest, TFindBuildingsResponse>(
      'storage/addressation/find-buildings',
      request,
      options,
    );
  }

  async findClientAreas(
    dto: TFindClientAreasRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindClientAreasResponse> {
    return await this.post<TFindClientAreasRequest, TFindClientAreasResponse>(
      'storage/addressation/find-client-areas',
      dto,
      options,
    );
  }

  async findElements(
    dto: TSelectAddressingElementsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TSelectAddressingElementsResponse> {
    const page = await this.post<TSelectAddressingElementsRequest, TSelectAddressingElementsResponse>(
      'storage/addressation/find',
      dto,
      options,
    );

    page.items.forEach((item) => {
      item.type = dto.element_type;
    });

    return page;
  }

  async findLoadingLines(
    dto: TFindLoadingLinesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindLoadingLinesResponse> {
    return await this.post<TFindLoadingLinesRequest, TFindLoadingLinesResponse>(
      'storage/addressation/find-loading-lines',
      dto,
      options,
    );
  }

  async findStorageCells(
    dto: TFindStorageCellsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindStorageCellsResponse> {
    return await this.post<TFindStorageCellsRequest, TFindStorageCellsResponse>(
      'storage/addressation/find-storage-cells',
      dto,
      options,
    );
  }

  async findStorageSubZones(
    dto: TFindStorageSubZonesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindStorageSubZonesResponse> {
    return await this.post<TFindStorageSubZonesRequest, TFindStorageSubZonesResponse>(
      'storage/addressation/find-storage-racks',
      dto,
      options,
    );
  }

  async findStorageZones(
    dto: TFindStorageZonesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindStorageZonesResponse> {
    return await this.post<TFindStorageZonesRequest, TFindStorageZonesResponse>(
      'storage/addressation/find-storage-sections',
      dto,
      options,
    );
  }

  async getAddressationTypes(
    dto: TGetAddressationTypesRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetAddressationTypesResponse> {
    return await this.post<TGetAddressationTypesRequest, TGetAddressationTypesResponse>(
      'storage/addressation/get-addressation-types',
      dto,
      options,
    );
  }

  async getBuildingRelations(
    dto: TGetBuildingRelationsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetBuildingRelationsResponse> {
    return await this.post<TGetBuildingRelationsRequest, TGetBuildingRelationsResponse>(
      'storage/addressation/get-building-relations',
      dto,
      options,
    );
  }

  async getSectionRelations(
    dto: TGetSectionRelationsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetSectionRelationsResponse> {
    return await this.post<TGetSectionRelationsRequest, TGetSectionRelationsResponse>(
      'storage/addressation/get-section-relations',
      dto,
      options,
    );
  }

  async getRackRelations(
    dto: TGetRackRelationsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetRackRelationsResponse> {
    return await this.post<TGetRackRelationsRequest, TGetRackRelationsResponse>(
      'storage/addressation/get-rack-relations',
      dto,
      options,
    );
  }

  async getCellRelations(
    dto: TGetCellRelationsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetCellRelationsResponse> {
    return await this.post<TGetCellRelationsRequest, TGetCellRelationsResponse>(
      'storage/addressation/get-cell-relations',
      dto,
      options,
    );
  }

  async getCellBarcode(dto: TGetCellBarcodeRequest, options?: Omit<Options, 'json'>): Promise<string> {
    const response = await this.post<TGetCellBarcodeRequest, TGetCellBarcodeResponse>(
      'storage/addressation/get-cell-barcode',
      dto,
      options,
    );

    return response.barcode_content;
  }

  async getAddressationElementById(
    elementType: StorageAddressingElementType.BUILDING,
    elementId: string,
    options?: Omit<Options, 'json'>,
  ): Promise<TBuilding | null>;
  async getAddressationElementById(
    elementType: StorageAddressingElementType.SECTION,
    elementId: string,
    options?: Omit<Options, 'json'>,
  ): Promise<TStorageZone | null>;
  async getAddressationElementById(
    elementType: StorageAddressingElementType.RACK,
    elementId: string,
    options?: Omit<Options, 'json'>,
  ): Promise<TStorageSubZone | null>;
  async getAddressationElementById(
    elementType: StorageAddressingElementType.CELL,
    elementId: string,
    options?: Omit<Options, 'json'>,
  ): Promise<TStorageCell | null>;
  async getAddressationElementById(
    elementType: StorageAddressingElementType,
    elementId: string,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetAddressationElementByIdResponse['element']> {
    const response = await this.post<TGetAddressationElementByIdRequest, TGetAddressationElementByIdResponse>(
      'storage/addressation/get-addressing-element-by-id',
      {
        element_id: elementId,
        element_type: elementType,
      },
      options,
    );

    return response.element;
  }
}

export const storageAddressationTransport = new StorageAddressationTransport();
