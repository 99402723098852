import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useEvent } from 'react-use-event-hook';
import { Button } from '../atoms/button';
import { Command } from './command';

type TComboboxInputProps = {
  inputPlaceholder: string;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onInputReset?: VoidFunction;
};

export function ComboboxInput({ inputPlaceholder, inputValue, onInputChange, onInputReset }: TComboboxInputProps): JSX.Element {
  const [input, setInput] = useState<string>('');

  const handleInputChange = useEvent((value: string) => {
    setInput(value);
    onInputChange?.(value);
  });
  const handleInputResetClick = useEvent(() => handleInputChange(''));

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset input only on unmount
  useEffect(() => handleInputResetClick, []);

  return (
    <div className="relative w-full">
      <Command.Input onValueChange={handleInputChange} placeholder={inputPlaceholder} value={inputValue ?? input} />

      {!!input && (
        <Button
          icon={<X />}
          className="absolute right-2 top-[0.65625rem] h-6 p-1 opacity-70 hover:opacity-100"
          onClick={onInputReset ?? handleInputResetClick}
          size="xs"
          variant="ghost"
        />
      )}
    </div>
  );
}
