function fallbackEmptyStringToUndefined<T>(value: T): T | undefined {
  if (value === '') {
    return undefined;
  }

  return value;
}

export type PropertyWhitelist<T extends Record<string, any>> = Array<Extract<keyof T, string>>;

export function mixinGlobalFiltersToRequest<T extends Record<string, any>>(
  globalFilters: Record<string, any>,
  propertyWhitelist: Array<Extract<keyof T, string>>,
  request: T,
): T {
  const localRequest = request as Record<string, any>;

  const requestKeys = Array.from(new Set(Object.keys(localRequest).concat(propertyWhitelist)));

  return requestKeys.reduce((acc: any, key: string) => {
    if (globalFilters[key] && globalFilters[key] instanceof Array) {
      const localValue = localRequest[key] ?? [];
      const globalValue = globalFilters[key]?.length ? globalFilters[key] : undefined;

      acc[key] = localValue.length > 0 ? localValue : globalValue;

      return acc;
    }

    acc[key] = fallbackEmptyStringToUndefined(localRequest[key]) ?? fallbackEmptyStringToUndefined(globalFilters[key]);

    return acc;
  }, {}) as T;
}
