import { cn } from '@/_shared/utils';
import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import { cva } from 'class-variance-authority';
import { ChevronDown } from 'lucide-react';
import React from 'react';

export const navigationMenuTriggerStyle = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:bg-slate-100 focus:ring-ring focus:ring-2 disabled:opacity-50 dark:focus:bg-slate-800 disabled:pointer-events-none bg-transparent hover:bg-slate-200 dark:hover:bg-slate-800 dark:text-slate-100 dark:hover:text-slate-200 data-[state=open]:bg-slate-50 dark:data-[state=open]:bg-slate-800 data-[active]:bg-slate-800 data-[active]:text-white dark:data-[active]:bg-slate-800 data-[state=open]:data-[active]:bg-slate-800 h-10 py-2 px-4 group w-max',
);

export const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof RNavigationMenu.Trigger>,
  React.ComponentPropsWithoutRef<typeof RNavigationMenu.Trigger>
>(({ className, children, ...props }, ref) => (
  <RNavigationMenu.Trigger className={cn(navigationMenuTriggerStyle(), 'group', className)} ref={ref} {...props}>
    {children}{' '}
    <ChevronDown
      aria-hidden="true"
      className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
    />
  </RNavigationMenu.Trigger>
));

NavigationMenuTrigger.displayName = RNavigationMenu.Trigger.displayName;
