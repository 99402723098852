import { useLocation } from 'react-router-dom';

export function useIsActiveDashboardPath(route: string, exact?: boolean): boolean {
  const location = useLocation();
  const dashboardBasePath = '/dashboard';

  if (!location.pathname.startsWith(dashboardBasePath)) {
    return false;
  }

  const [, dashboardPath] = location.pathname.split(dashboardBasePath + '/', 2);

  if (!dashboardPath) {
    return false;
  }

  return exact ? dashboardPath === route : dashboardPath.startsWith(route);
}
