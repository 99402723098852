import { Loader } from '@/_shared/components/atoms';
import { Dialog } from '@/_shared/components/organisms';
import { useLazyRef } from '@/_shared/hooks/use-lazy-ref';
import { nanoid } from 'nanoid';
import { ComponentType, createContext, ReactNode, Suspense, useContext, useEffect, useRef } from 'react';
import { useCounter } from './use-counter';

export type TDialogComponentCoreProps = { onCloseRequest?: VoidFunction };

type TDialogDescription = {
  Component: ComponentType<TDialogComponentCoreProps>;
  id: string;
  isOpen: boolean;
};

type TDialogManagerContext = {
  addDialog: (id: string, Component: ComponentType<TDialogComponentCoreProps>) => void;
  dialogs: Map<string, TDialogDescription>;
  removeAllDialogs: VoidFunction;
  removeDialog: (id: string) => void;
  updateDialog: (id: string, changes: Partial<TDialogDescription>) => void;
};

// NOTE: without cloning this might break in case we have multiple dialog context (would we ever do that?)
const dialogManagerInitialState: TDialogManagerContext = {
  addDialog: () => ({}),
  dialogs: new Map(),
  removeAllDialogs: () => ({}),
  removeDialog: () => ({}),
  updateDialog: () => ({}),
};

const dialogManagerContext = createContext<TDialogManagerContext>({
  ...dialogManagerInitialState,
});

type TDialogWrapperProps = { setIsOpen: (state: boolean) => void } & TDialogDescription;

function DialogWrapper({ Component, isOpen, setIsOpen }: TDialogWrapperProps): JSX.Element | null {
  return isOpen ? (
    <Dialog.Root defaultOpen={false} open={isOpen} onOpenChange={setIsOpen}>
      <Suspense
        fallback={
          <Dialog.Content className="w-30 flex justify-center p-8" hideCross>
            <Loader />
          </Dialog.Content>
        }
      >
        <Component onCloseRequest={() => setIsOpen(false)} />
      </Suspense>
    </Dialog.Root>
  ) : null;
}

export function DialogManager({ children }: { children: ReactNode }): JSX.Element {
  const { increase: forceRender } = useCounter();
  const dialogManagerRef = useRef({
    ...dialogManagerInitialState,
    addDialog(id: string, Component: ComponentType<TDialogComponentCoreProps>): void {
      this.dialogs.set(id, {
        Component,
        id,
        isOpen: true,
      });

      forceRender();
    },
    removeAllDialogs() {
      const dialogIds = this.dialogs.keys();

      for (const dialogId of dialogIds) {
        this.removeDialog(dialogId);
      }
    },
    removeDialog(id: string): void {
      this.dialogs.delete(id);

      forceRender();
    },
    updateDialog(id: string, changes: Partial<TDialogDescription>): void {
      const currentDialog = this.dialogs.get(id);

      if (currentDialog) {
        const newDialog: TDialogDescription = Object.assign({}, currentDialog, changes);

        this.dialogs.set(id, newDialog);
      }

      forceRender();
    },
  });

  return (
    <dialogManagerContext.Provider value={dialogManagerRef.current}>
      <>
        {children}

        <div>
          {Array.from(dialogManagerRef.current.dialogs, ([key, value]): JSX.Element => {
            const setIsOpen = (state: boolean) => dialogManagerRef.current.updateDialog(key, { isOpen: state });

            return <DialogWrapper key={key} {...value} setIsOpen={setIsOpen} />;
          })}
        </div>
      </>
    </dialogManagerContext.Provider>
  );
}

export type TUseDialogReturn<Props extends TDialogComponentCoreProps> = {
  close: (id: string) => void;
  open: () => string;
  openWithProps: (additionalProps: Props) => string;
};

export function useDialog<Props extends TDialogComponentCoreProps>(
  Component: ComponentType<Props>,
  removeOnHookUnmount: boolean = false,
): TUseDialogReturn<Props> {
  const dialogManager = useContext(dialogManagerContext);
  const openedIds = useLazyRef(() => new Set<string>());

  const close = (id: string): void => {
    dialogManager.removeDialog(id);
    openedIds.current.delete(id);
  };

  const open = (): string => {
    const id = nanoid();

    dialogManager.addDialog(id, Component as ComponentType<TDialogComponentCoreProps>);
    openedIds.current.add(id);

    return id;
  };

  const openWithProps = (additionalProps: Props): string => {
    const id = nanoid();
    const component = (props: TDialogComponentCoreProps) => <Component {...props} {...additionalProps} />;

    dialogManager.addDialog(id, component);
    openedIds.current.add(id);

    return id;
  };

  useEffect(() => {
    return (): void => {
      if (removeOnHookUnmount) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        for (const id of openedIds.current) {
          dialogManager.removeDialog(id);
        }
      }
    };
  }, [dialogManager, openedIds, removeOnHookUnmount]);

  return { close, open, openWithProps };
}

export function useDialogManagerContext(): TDialogManagerContext {
  return useContext(dialogManagerContext);
}
