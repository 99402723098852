import { cn } from '@/_shared/utils';
import { ComponentProps, forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export type TTextareaProps = ComponentProps<typeof TextareaAutosize>;

export const Textarea = forwardRef<HTMLTextAreaElement, TTextareaProps>(({ className, ...props }, ref) => {
  return (
    <TextareaAutosize
      className={cn(
        'flex min-h-[60px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm shadow-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = 'Textarea';
