import { cn } from '@/_shared/utils/classnames';
import { CSSProperties, useMemo } from 'react';
import * as React from 'react';

type TTableCellProps = React.TdHTMLAttributes<HTMLTableCellElement> & {
  columnWidthRem?: string;
};

export const TableCell = React.forwardRef<HTMLTableCellElement, TTableCellProps>(
  ({ className, columnWidthRem, style, ...props }, ref) => {
    const combinedStyle = useMemo((): CSSProperties | undefined => {
      if (columnWidthRem === undefined) {
        return style ?? undefined;
      }

      return Object.assign(
        {
          width: columnWidthRem,
        },
        style,
      ) as CSSProperties;
    }, [columnWidthRem, style]);

    return (
      <td
        ref={ref}
        className={cn('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
        style={combinedStyle}
        {...props}
      />
    );
  },
);
TableCell.displayName = 'TableCell';
