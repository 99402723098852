import * as RDialog from '@radix-ui/react-dialog';
import { DialogBody } from './dialog-body';
import { DialogContent } from './dialog-content';
import { DialogDescription } from './dialog-description';
import { DialogFooter } from './dialog-footer';
import { DialogHeader } from './dialog-header';
import { DialogTitle } from './dialog-title';

export const Dialog = {
  Body: DialogBody,
  Content: DialogContent,
  Description: DialogDescription,
  Footer: DialogFooter,
  Header: DialogHeader,
  Root: RDialog.Root,
  Title: DialogTitle,
  Trigger: RDialog.Trigger,
};
