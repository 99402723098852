import { Loader } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';

type TCommandLoadingProps = Omit<React.ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>, 'children'> & {
  className?: string;
};

export const CommandLoading = React.forwardRef<React.ElementRef<typeof CommandPrimitive.Loading>, TCommandLoadingProps>(
  ({ className, ...props }, ref) => (
    <CommandPrimitive.Loading ref={ref} {...props}>
      <div className={cn('py-8', className)}>
        <Loader />
      </div>
    </CommandPrimitive.Loading>
  ),
);

CommandLoading.displayName = CommandPrimitive.Loading.displayName;
