import {
  TAssignment,
  TAssignmentTask,
  TAssignmentType,
  TAssignRequest,
  TAssignResponse,
  TCloseAssignmentRequest,
  TCloseAssignmentResponse,
  TCreateAssignmentRequest,
  TCreateAssignmentResponse,
  TFindAssignmentsRequest,
  TFindAssignmentsResponse,
  TGetAssignmentTasksResponse,
  TGetAssignmentTypesResponse,
  TGetFullAssignmentRequest,
  TGetFullAssignmentResponse,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class AssignmentTransport extends BaseTransport {
  async assignAssignment(dto: TAssignRequest, options?: Omit<Options, 'json'>): Promise<TAssignment> {
    const response = await this.post<TAssignRequest, TAssignResponse>('assignment/assign', dto, options);

    return response.updated_assignment;
  }

  async closeAssignment(dto: TCloseAssignmentRequest, options?: Omit<Options, 'json'>): Promise<void> {
    return await this.post<TCloseAssignmentRequest, TCloseAssignmentResponse>('assignment/close', dto, options);
  }

  async createAssignment(
    dto: TCreateAssignmentRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TCreateAssignmentResponse> {
    return await this.post<TCreateAssignmentRequest, TCreateAssignmentResponse>(
      'assignment/create-assignment',
      dto,
      options,
    );
  }

  async findAssignments(
    dto: TFindAssignmentsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindAssignmentsResponse> {
    return await this.post<TFindAssignmentsRequest, TFindAssignmentsResponse>(
      'assignment/find-assignments',
      dto,
      options,
    );
  }

  async getFull(
    request: TGetFullAssignmentRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetFullAssignmentResponse> {
    return await this.post<TGetFullAssignmentRequest, TGetFullAssignmentResponse>(
      'assignment/get-full',
      request,
      options,
    );
  }

  async getAssignmentTasks(assignmentId: string, options?: Omit<Options, 'json'>): Promise<Array<TAssignmentTask>> {
    const response = await this.get<TGetAssignmentTasksResponse>(
      `assignment/get-assignment-tasks?id=${assignmentId}`,
      options,
    );

    return response.tasks;
  }

  async getAssignmentTypes(options?: Omit<Options, 'json'>): Promise<Array<TAssignmentType>> {
    const response = await this.get<TGetAssignmentTypesResponse>('assignment/get-assignment-types', options);

    return response.assignment_types;
  }
}

export const assignmentTransport = new AssignmentTransport();
