import { Toast } from '@/_shared/components/atoms';
import { useToast } from '@/_shared/hooks';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <Toast.Provider duration={3000}>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast.Root key={id} {...props} style={{ ...props.style, right: 0 }}>
          <div className="grid gap-1">
            {title && <Toast.Title>{title}</Toast.Title>}

            {description && <Toast.Description>{description}</Toast.Description>}
          </div>

          {action}

          <Toast.Close />
        </Toast.Root>
      ))}

      <Toast.Viewport className="items-end" />
    </Toast.Provider>
  );
}
