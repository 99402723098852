import { useOptionalObservable } from '@/_shared/hooks/use-observable';
import { PaginationStateService } from '@/_shared/services/pagination-state.service';
import { TResponsePagination } from '@/_shared/types';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';

export function getNextPageParamImpl(lastPage: TResponsePagination): number | undefined {
  return lastPage.page + 1 < lastPage.total_pages ? lastPage.page + 1 : undefined;
}

type TPaginatedQueryParams<Q extends object> = {
  paginationStateService?: PaginationStateService;
  queryParams?: Q;
};

export function makePaginatedQuery<Q extends object, T extends TPaginatedQueryParams<Q>, E = any>(
  fetcher: (queryParams: Q) => UseQueryResult<TResponsePagination<E>>,
) {
  return (params?: T) => {
    const paginationStateService = params?.paginationStateService;
    const queryParams = params?.queryParams;
    const pagination = useOptionalObservable(paginationStateService?.filters$, paginationStateService?.value);
    const paramsWithPage = Object.assign(queryParams ?? {}, { page: pagination?.currentPage ?? 0 } as Q);
    const request = fetcher(paramsWithPage);

    useEffect(() => {
      if (request.data) {
        paginationStateService?.updateFilter({
          isLoading: false,
          totalItems: request.data.total_items,
          totalPages: request.data.total_pages,
        });
      }
    }, [paginationStateService, request.data]);

    return request;
  };
}
