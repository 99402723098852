import { cn } from '@/_shared/utils';
import * as RDialog from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { DialogOverlay } from './dialog-overlay';
import { DialogPortal } from './dialog-portal';

type TDialogContentProps = ComponentPropsWithoutRef<typeof RDialog.Content> & {
  fullHeight?: boolean;
  hideCross?: boolean;
  preventBackdropClickClose?: boolean;
  preventEscClose?: boolean;
};

export const DialogContent = forwardRef<ElementRef<typeof RDialog.Content>, TDialogContentProps>(
  ({ className, children, fullHeight, hideCross, preventBackdropClickClose, preventEscClose, ...props }, ref) => {
    const handleOnPointerDown = preventBackdropClickClose ? (event: CustomEvent) => event.preventDefault() : undefined;
    const handleOnEscapeKeyDown = preventEscClose ? (event: KeyboardEvent) => event.preventDefault() : undefined;

    return (
      <DialogPortal>
        <DialogOverlay />

        <RDialog.Content
          className={cn(
            'fixed z-50 flex max-h-[calc(100%_-_4rem)] w-full flex-col gap-4 overflow-hidden rounded-b-lg bg-white pl-6 pr-3 pt-12 animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:max-w-lg sm:rounded-lg sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0 md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-7xl',
            'dark:bg-slate-900',
            { 'h-[calc(100%_-_4rem)]': fullHeight },
            className,
          )}
          onEscapeKeyDown={handleOnEscapeKeyDown}
          onPointerDownOutside={handleOnPointerDown}
          ref={ref}
          {...props}
        >
          {children}

          {!hideCross && (
            <RDialog.Close className="absolute right-4 top-4 rounded-sm opacity-70 transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-slate-100 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 dark:data-[state=open]:bg-slate-800">
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </RDialog.Close>
          )}
        </RDialog.Content>
      </DialogPortal>
    );
  },
);

DialogContent.displayName = RDialog.Content.displayName;
