import { Tooltip } from '@/_shared/components/atoms';
import { ThemeProvider, Toaster } from '@/_shared/components/organisms';
import { toast } from '@/_shared/hooks';
import { DialogManager } from '@/_shared/hooks/use-dialog';
import { ApiResponseError } from '@/_shared/transports';
import { i18n } from '@/_shared/utils/i18n';
import {
  matchQuery,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryMeta,
} from '@tanstack/react-query';
import { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';

const QUERY_MAX_RETRIES = 2;
const QUERY_STALE_AFTER = 15 * 60 * 1000; // 15 minutes

function showErrorToast(error: unknown, queryMeta: QueryMeta) {
  if (queryMeta?.skipErrorToast) {
    return;
  }

  const fallbackErrorDescription = queryMeta?.fallbackErrorDescription ?? 'Что-то пошло не так...';
  const errorTitle = queryMeta?.errorTitle ?? 'Ошибка запроса данных';
  const errorDescription =
    queryMeta?.errorDescription ??
    (error instanceof ApiResponseError ? error.toServerError() : fallbackErrorDescription);
  const errorToastDuration = queryMeta?.errorToastDuration ?? 3000;

  toast({
    title: errorTitle,
    description: errorDescription,
    duration: errorToastDuration,
    variant: 'destructive',
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        if (error instanceof ApiResponseError && error.kind === 'network') {
          return failureCount <= QUERY_MAX_RETRIES;
        }

        return false;
      },
      staleTime: QUERY_STALE_AFTER,
    },
  },
  mutationCache: new MutationCache({
    onSettled: (_data: unknown, error: unknown | null, _variables, _context, mutation) => {
      if (error) {
        showErrorToast(error, mutation.meta ?? {});
      }
    },
    onSuccess: async (data, _variables, _context, mutation) => {
      await queryClient.invalidateQueries({
        predicate: (query: Query) => {
          return !!mutation.meta?.invalidates?.some((queryKey) => matchQuery({ queryKey }, query));
        },
      });

      if (mutation.meta?.onSuccess) {
        void mutation.meta.onSuccess(data);
      }
    },
  }),
  queryCache: new QueryCache({
    onError: (error: unknown, query: Query) => {
      showErrorToast(error, query.meta ?? {});
    },
  }),
});

export function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <I18nextProvider defaultNS="common" i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <>
              <DialogManager>
                <Tooltip.Provider delayDuration={400}>
                  <RouterProvider router={router} />
                </Tooltip.Provider>
              </DialogManager>

              {createPortal(<Toaster />, document.body)}
            </>
          </ThemeProvider>
        </QueryClientProvider>
      </I18nextProvider>
    </Suspense>
  );
}
