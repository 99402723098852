import {
  TCreateProductRequest,
  TCreateProductResponse,
  TDeleteProductRequestById,
  TFindProductsRequest,
  TFindProductsResponse,
  TGetProductAddressationStocksRequest,
  TGetProductAddressationStocksResponse,
  TGetProductDetailsRequest,
  TGetProductDetailsResponse,
  TGetProductRequest,
  TGetProductResponse,
  TGetTypesOfPricesRequest,
  TGetTypesOfPricesResponse,
  TUpdateProductRequest,
  TUpdateProductResponse,
} from '@/_shared/types';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

class ProductTransport extends BaseTransport {
  async findProducts(dto: TFindProductsRequest, options?: Omit<Options, 'json'>): Promise<TFindProductsResponse> {
    return await this.post<TFindProductsRequest, TFindProductsResponse>('product/find-products', dto, options);
  }

  async getProductAddressationStocks(
    dto: TGetProductAddressationStocksRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetProductAddressationStocksResponse> {
    return await this.post<TGetProductAddressationStocksRequest, TGetProductAddressationStocksResponse>(
      'product/get-product-addressation-stocks',
      dto,
      options,
    );
  }

  async getProductDetails(
    dto: TGetProductDetailsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetProductDetailsResponse> {
    return await this.post<TGetProductDetailsRequest, TGetProductDetailsResponse>('product/details', dto, options);
  }

  async getTypesOfPrices(options?: Omit<Options, 'json'>): Promise<TGetTypesOfPricesResponse> {
    return await this.post<TGetTypesOfPricesRequest, TGetTypesOfPricesResponse>(
      'product/types-of-prices',
      void 0,
      options,
    );
  }

  async deleteProduct(dto: TDeleteProductRequestById, options?: Omit<Options, 'json'>): Promise<void> {
    return await this.post<TDeleteProductRequestById, void>(
      'product/delete',
      dto,
      options,
    )
  }

  async createProduct(dto: TCreateProductRequest, options?: Omit<Options, 'json'>): Promise<TCreateProductResponse> {
    return await this.post<TCreateProductRequest, TCreateProductResponse>(
      'product/create',
      dto,
      options
    )
  }

  async updateProduct(dto: TUpdateProductRequest,options?: Omit<Options, 'json'>): Promise<TUpdateProductResponse> {
    return await this.post<TUpdateProductRequest, TUpdateProductResponse>(
      'product/update',
      dto,
      options
    )
  }

  async getProduct(productCode: string, options?: Omit<Options, 'json'>): Promise<TGetProductResponse> {
    return await this.get<TGetProductResponse>(
      'product',
      {
        searchParams: {
          product_code: productCode
        },
        ...options
      }
    )
  }
}

export const productTransport = new ProductTransport();
