import { useCallback, useEffect, useMemo, useRef, useSyncExternalStore } from 'react';
import { BehaviorSubject, Observable, of } from 'rxjs';
import useConstant from 'use-constant';

const noopObservable = of();

export function useOptionalObservable<T>(observable: Observable<T> | undefined, defaultValue: T): T {
  return useObservable(observable ?? noopObservable, defaultValue);
}

export function useObservable<T>(observable: Observable<T> | undefined, defaultValue: T): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const subject = useConstant(() => new BehaviorSubject(defaultValue));

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (observable) {
      const subscription = observable.subscribe((value: T) => subject.next(value));

      return () => subscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable]);

  const getValue = useCallback(() => subject.getValue(), [subject]);

  const subscribe = useMemo(() => {
    return (callback: (value: T) => void) => {
      const subscription = subject.subscribe(callback);

      return () => subscription.unsubscribe();
    };
  }, [subject]);

  return useSyncExternalStore<T>(subscribe, getValue, getValue);
}

export function useObservableEffect<T>(observable: Observable<T>, touchFn: (value: T) => Promise<void> | void): void {
  const stableTouchFn = useRef((value: T) => void touchFn(value));

  stableTouchFn.current = (value: T) => void touchFn(value);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const subscription = observable.subscribe(stableTouchFn.current);

    return () => subscription.unsubscribe();
  }, [observable, touchFn]);
}
