import { Button, FormField, SimpleTooltip } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import get from 'lodash.get';
import { FolderOpen } from 'lucide-react';
import { ChangeEventHandler, useContext, useEffect, useMemo, useRef } from 'react';
import { Path, useController } from 'react-hook-form';
import { useEvent } from 'react-use-event-hook';
import { FormDisabledContext } from './context';

type TFormCheckboxFieldProps<T extends Record<string, any>, M extends boolean> = {
  allowUnregister?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  multiple?: M;
  name: Path<T>;
  onBlur?: VoidFunction;
  onChange?: (value: M extends true ? Array<File> : File | undefined) => void;
};

export function FormFileField<T extends Record<string, any>, M extends boolean>({
  allowUnregister,
  className,
  disabled,
  multiple,
  label,
  name,
  onBlur,
  onChange,
}: TFormCheckboxFieldProps<T, M>): JSX.Element {
  const disabledContext = useContext(FormDisabledContext);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { field, formState } = useController<T, Path<T>>({ name, shouldUnregister: allowUnregister });
  const isInputDisabled = disabled || formState.isSubmitting || formState.isLoading || !!disabledContext;
  const fieldError = get(formState.errors, name);
  const errorMessage = fieldError?.message as string | undefined;

  const handleSelectAppFileButtonClick = useEvent(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      fileInputRef.current.click();
    }
  });

  const fieldContents = useMemo(() => {
    const files = multiple ? Array.from<File>(field.value ?? []) : field.value ? [field.value as File] : [];
    const file = files?.[0];

    if (!files.length) {
      return (
        <div className="flex items-center justify-between gap-2">
          <div className="h-10 flex-1 rounded-md border border-dashed p-2 text-sm">
            <span className="opacity-70">{multiple ? 'Выберите файлы' : 'Выберите файл'}</span>
          </div>

          <SimpleTooltip content={multiple ? 'Выбрать файлы' : 'Выбрать файл'}>
            <Button
              disabled={isInputDisabled}
              icon={<FolderOpen />}
              onBlur={onBlur}
              onClick={handleSelectAppFileButtonClick}
              variant="default"
            />
          </SimpleTooltip>
        </div>
      );
    }

    const selectedFileName = multiple ? `Выбрано ${files.length} файлов` : file?.name ?? 'xxxx';

    return (
      <div className="flex items-center justify-between gap-2">
        <SimpleTooltip content={selectedFileName}>
          <div className="h-10 flex-1 truncate rounded-md border border-dashed p-2 text-sm">{selectedFileName}</div>
        </SimpleTooltip>

        <SimpleTooltip content={multiple ? 'Выбрать другие файлы' : 'Выбрать другой файл'}>
          <Button
            disabled={isInputDisabled}
            icon={<FolderOpen />}
            onBlur={onBlur}
            onClick={handleSelectAppFileButtonClick}
            variant="subtle"
          />
        </SimpleTooltip>
      </div>
    );
  }, [field.value, handleSelectAppFileButtonClick, isInputDisabled, multiple, onBlur]);

  const handleFileInputChange: ChangeEventHandler<HTMLInputElement> = useEvent((event) => {
    const files = Array.from(event.target.files ?? []);
    const selection = multiple ? files : files?.[0];

    field.onChange?.(selection);
    field.onBlur?.();

    onChange?.(selection as any);
  });

  useEffect(() => {
    if (fileInputRef.current && (field.value === undefined || field.value === null)) {
      fileInputRef.current.value = '';
    }
  }, [field.value]);

  return (
    <>
      <FormField
        className={cn('', className)}
        errorMessage={errorMessage}
        label={label}
        labelClassName="w-auto"
        name={name}
      >
        {fieldContents}
      </FormField>

      <input
        accept="application/vnd.android.package-archive"
        className="invisible fixed left-[-99999px] top-[-99999px]"
        disabled={isInputDisabled}
        name="app_file"
        onChange={handleFileInputChange}
        ref={fileInputRef}
        type="file"
      />
    </>
  );
}
