import { useLazyRef } from '@/_shared/hooks';
import { cn, isDevelopment, parseJson, stringifyJson } from '@/_shared/utils';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import * as RForm from '@radix-ui/react-form';
import { ClassValue } from 'clsx';
import React, { MutableRefObject, ReactNode, Ref, useCallback, useEffect } from 'react';
import {
  type FieldValues,
  FormProvider,
  type UseFormHandleSubmit,
  type UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { ZodType, z } from 'zod';

type AllowedSchemaType = ZodType<any, any, any>;

const isAbleToUseDevTools = isDevelopment();

export type TSubmitHandler<TInput extends FieldValues, TOutput extends FieldValues = TInput> = (
  data: TOutput,
  form: UseFormReturn<TInput, any, TOutput>,
  event?: React.BaseSyntheticEvent,
) => unknown | Promise<unknown>;

type FormSubmitHandler<
  TFieldValues extends FieldValues,
  TTransformedValues extends FieldValues | undefined = undefined,
> = TTransformedValues extends undefined
  ? TSubmitHandler<TFieldValues>
  : TTransformedValues extends FieldValues
    ? TSubmitHandler<TTransformedValues>
    : never;

type TFormRootProps<TFormSchema extends AllowedSchemaType, TInput extends FieldValues, TOutput extends FieldValues> = {
  children?: ReactNode;
  className?: ClassValue;
  devTools?: boolean;
  disabled?: boolean;
  formContextRef?: MutableRefObject<UseFormReturn<TInput, TOutput> | undefined>;
  mode?: UseFormProps['mode'];
  onSubmit: FormSubmitHandler<TInput, TOutput>; // Parameters<UseFormHandleSubmit<Input, Output>>[0];
  ref?: Ref<HTMLFormElement>;
  reValidateMode?: UseFormProps['reValidateMode'];
  schema: TFormSchema;
} & (
  | {
      defaultValues: TInput;
    }
  | {
      defaultValues?: TInput;
      localStorageKey: string;
      saveMode?: 'onChange' | 'onValidSubmit';
    }
  | {
      defaultValues?: TInput;
      sessionStorageKey: string;
      saveMode?: 'onChange' | 'onValidSubmit';
    }
);

export function FormRoot<
  TFormSchema extends AllowedSchemaType,
  TInput extends z.input<TFormSchema>,
  TOutput extends z.output<TFormSchema>,
>({
  children,
  className,
  devTools = false,
  defaultValues: outerDefaultValues,
  disabled,
  formContextRef,
  mode,
  onSubmit,
  ref,
  reValidateMode,
  schema,
  ...props
}: TFormRootProps<TFormSchema, TInput, TOutput>): JSX.Element {
  const localStorageKey = 'localStorageKey' in props && props.localStorageKey ? props.localStorageKey : undefined;
  const sessionStorageKey =
    'sessionStorageKey' in props && props.sessionStorageKey ? props.sessionStorageKey : undefined;
  const saveMode = 'saveMode' in props && props.saveMode ? props.saveMode : 'onValidSubmit';

  const formClassName = cn(
    'w-full flex flex-col flex-1 items-center justify-center rounded mx-auto z-10 sticky bg-white overflow-auto',
    className,
  );
  const defaultValues = useLazyRef(() => {
    let storedValuesJson: string | null = null;

    if (sessionStorageKey) {
      storedValuesJson = sessionStorage.getItem(sessionStorageKey);
    } else if (localStorageKey) {
      storedValuesJson = localStorage.getItem(localStorageKey);
    }

    if (storedValuesJson) {
      try {
        return parseJson(storedValuesJson) as TInput;
      } catch (error) {
        console.error(error);
        return outerDefaultValues;
      }
    }

    return outerDefaultValues;
  });

  const formMethods = useForm<TInput, any, TOutput>({
    criteriaMode: 'firstError',
    defaultValues: defaultValues.current,
    delayError: 300,
    disabled,
    mode: mode ?? 'onBlur',
    resolver: zodResolver(schema),
    reValidateMode: reValidateMode ?? 'onChange',
    shouldFocusError: false,
  });
  const { control, handleSubmit } = formMethods;

  const handleOnSubmit = useCallback(
    (data: TOutput, event: React.BaseSyntheticEvent) => {
      try {
        if (saveMode === 'onValidSubmit' && (sessionStorageKey || localStorageKey)) {
          const newValuesJson = stringifyJson(data);

          try {
            if (sessionStorageKey) {
              sessionStorage.setItem(sessionStorageKey, newValuesJson);
            } else if (localStorageKey) {
              localStorage.setItem(localStorageKey, newValuesJson);
            }
          } catch (error) {
            console.error(error);
          }
        }

        return onSubmit(data, formMethods as any, event);
      } catch (error) {
        console.error(error);
        return;
      }
    },
    [formMethods, onSubmit],
  ) as Parameters<UseFormHandleSubmit<TInput, TOutput>>[0];

  if (formContextRef) {
    formContextRef.current = formMethods;
  }

  useEffect(() => {
    if (saveMode === 'onChange' && (sessionStorageKey || localStorageKey)) {
      const { unsubscribe } = formMethods.watch((value) => {
        const newValuesJson = stringifyJson(value);

        if (sessionStorageKey) {
          sessionStorage.setItem(sessionStorageKey, newValuesJson);
        } else if (localStorageKey) {
          localStorage.setItem(localStorageKey, newValuesJson);
        }
      });

      return unsubscribe;
    }
  }, [formMethods.watch, localStorageKey, saveMode, sessionStorageKey]);

  return (
    <RForm.Root className={formClassName} ref={ref} onSubmit={handleSubmit(handleOnSubmit)}>
      <FormProvider {...formMethods}>{children}</FormProvider>

      {isAbleToUseDevTools && devTools && <DevTool control={control} />}
    </RForm.Root>
  );
}

FormRoot.displayName = 'FormRoot';
