import { BaseTransport } from '@/_shared/transports/base.transport';
import { TGetJobInfoRequest, TGetJobInfoResponse } from '@/_shared/types';

class JobTransport extends BaseTransport {
  async getJobInfo<T>(request: TGetJobInfoRequest) {
    return await this.post<TGetJobInfoRequest, TGetJobInfoResponse<T>>('job/get-info', request);
  }
}

export const jobTransport = new JobTransport();
