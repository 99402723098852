import * as React from 'react';
import * as RAccordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { cn } from '@/_shared/utils';

export const AccordionItem = React.forwardRef<
  React.ElementRef<typeof RAccordion.Item>,
  React.ComponentPropsWithoutRef<typeof RAccordion.Item>
>(({ className, ...props }, ref) => <RAccordion.Item ref={ref} className={cn('border-b', className)} {...props} />);
AccordionItem.displayName = 'AccordionItem';

export const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof RAccordion.Trigger>,
  React.ComponentPropsWithoutRef<typeof RAccordion.Trigger>
>(({ className, children, ...props }, ref) => (
  <RAccordion.Header className="flex">
    <RAccordion.Trigger
      ref={ref}
      className={cn(
        'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
        className,
      )}
      {...props}
    >
      {children}
      <ChevronDownIcon className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200" />
    </RAccordion.Trigger>
  </RAccordion.Header>
));
AccordionTrigger.displayName = RAccordion.Trigger.displayName;

export const AccordionContent = React.forwardRef<
  React.ElementRef<typeof RAccordion.Content>,
  React.ComponentPropsWithoutRef<typeof RAccordion.Content>
>(({ className, children, ...props }, ref) => (
  <RAccordion.Content
    ref={ref}
    className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </RAccordion.Content>
));
AccordionContent.displayName = RAccordion.Content.displayName;

export const Accordion = {
  Root: RAccordion.Root,
  Content: AccordionContent,
  Item: AccordionItem,
  Trigger: AccordionTrigger,
};
