import { cn } from '@/_shared/utils';
import * as RAvatar from '@radix-ui/react-avatar';
import * as React from 'react';

export const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof RAvatar.Fallback>,
  React.ComponentPropsWithoutRef<typeof RAvatar.Fallback>
>(({ className, ...props }, ref) => (
  <RAvatar.Fallback
    className={cn('flex h-full w-full items-center justify-center rounded-full bg-muted', className)}
    ref={ref}
    {...props}
  />
));

AvatarFallback.displayName = RAvatar.Fallback.displayName;
