import { BaseTransport } from '@/_shared/transports/base.transport';
import { TGetAttachmentsRequest, TGetAttachmentsResponse } from '@/_shared/types';
import { Options } from 'ky';

class AttachmentTransport extends BaseTransport {
  async getAttachments(
    request: TGetAttachmentsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetAttachmentsResponse> {
    return await this.post<TGetAttachmentsRequest, TGetAttachmentsResponse>(
      'attachment/get-attachments',
      request,
      options,
    );
  }
}

export const attachmentTransport = new AttachmentTransport();
