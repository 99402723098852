import {
  getEnvironment,
  getEnvironmentVariable,
  getPackageName,
  getPackageVersion,
  isDevelopment,
  isProduction,
} from '@/_shared/utils';
import * as SentryBrowser from '@sentry/browser';
import * as SentryReact from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

const SENTRY_DNS = getEnvironmentVariable<string>('VITE_SENTRY_DSN');

if (SENTRY_DNS) {
  SentryReact.init({
    dsn: SENTRY_DNS,
    environment: getEnvironment(),
    integrations: [
      new SentryBrowser.BrowserTracing({
        routingInstrumentation: SentryReact.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new SentryBrowser.Replay(),
    ],
    release: `${getPackageName()}@${getPackageVersion()}`,
    transport: SentryReact.makeBrowserOfflineTransport(SentryReact.makeFetchTransport),
    // Performance Monitoring
    tracesSampleRate: isProduction() ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: isProduction() ? 0.1 : isDevelopment() ? 0.0 : 1.0,
    replaysOnErrorSampleRate: isDevelopment() ? 0.0 : 1.0,
  });
}
