import { cn } from '@/_shared/utils';
import React from 'react';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';

export const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.Content>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <RDropdownMenu.Portal>
    <RDropdownMenu.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </RDropdownMenu.Portal>
));

DropdownMenuContent.displayName = RDropdownMenu.Content.displayName;
