import { cn } from '@/_shared/utils';
import * as RDialog from '@radix-ui/react-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

export const DialogTitle = forwardRef<ElementRef<typeof RDialog.Title>, ComponentPropsWithoutRef<typeof RDialog.Title>>(
  ({ className, ...props }, ref) => (
    <RDialog.Title
      ref={ref}
      className={cn('text-lg font-semibold text-slate-900', 'dark:text-slate-50', className)}
      {...props}
    />
  ),
);

DialogTitle.displayName = RDialog.Title.displayName;
