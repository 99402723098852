import { ScrollArea } from '@/_shared/components/atoms';
import React, { forwardRef } from 'react';

export const DialogBody = forwardRef<
  React.ElementRef<typeof ScrollArea>,
  React.ComponentPropsWithoutRef<typeof ScrollArea>
>(({ className, ...props }, ref) => {
  return <ScrollArea className={className} ref={ref} {...props} />;
});

DialogBody.displayName = 'DialogBody';
