import { Button } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { HelpCircle } from 'lucide-react';
import { ElementRef, forwardRef } from 'react';

export const tourButtonVariants = cva('', {
  defaultVariants: {
    size: 'default',
  },
  variants: {
    size: {
      default: 'h-10 w-10 p-2 rounded-md',
      sm: 'h-9 w-9 px-2 p-2 rounded-md',
      lg: 'h-11 h-11 px-8 p-2 rounded-md',
      xs: 'h-8 w-8 px-2 p-2 rounded-md',
      icon: 'h-8 w-8 px-2 p-2 rounded-md',
    },
  },
});

type TTourButtonProps = {
  className?: string;
  onClick: VoidFunction;
} & VariantProps<typeof tourButtonVariants>;

export const TourButton = forwardRef<ElementRef<typeof Button>, TTourButtonProps>(
  ({ className, onClick, size }: TTourButtonProps, ref): JSX.Element => {
    const tourButtonClassName = cn(tourButtonVariants({ size }), className);

    return (
      <Button
        className={tourButtonClassName}
        icon={<HelpCircle />}
        onClick={onClick}
        ref={ref}
        size={size}
        variant="outline"
      />
    );
  },
);
TourButton.displayName = 'TourButton';
