import { FacetedFilter, TFacetedFilterOption } from '@/_shared/components/organisms/faceted-filter';
import { AssignmentPriority } from '@/_shared/enums';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use-event-hook';

const assignmentPriorities: Array<AssignmentPriority> = [
  AssignmentPriority.HIGHEST,
  AssignmentPriority.HIGH,
  AssignmentPriority.NORMAL,
  AssignmentPriority.LOW,
];

function useAssignmentPriorityOptions(): Array<TFacetedFilterOption<AssignmentPriority>> {
  const { t } = useTranslation('warehouse_assignments');

  return assignmentPriorities.map((type) => ({ label: t(`assignment_priority.${type}`), value: type }));
}

type TFacetedAssignmentPriorityFilterProps = {
  buttonClassName?: string;
  column?: Column<any, any>;
  onAssignmentStatesSelection?: (assignmentTypes: Array<TFacetedFilterOption<AssignmentPriority>>) => void;
  onResetFilter?: VoidFunction;
  onSelection?: (values: Array<AssignmentPriority>) => void;
  placeholder?: string;
  selected?: Array<AssignmentPriority>;
};

export function FacetedAssignmentPriorityFilter({
  buttonClassName,
  placeholder = 'Приоритет',
  onAssignmentStatesSelection,
  onResetFilter,
  onSelection,
  selected,
}: TFacetedAssignmentPriorityFilterProps): JSX.Element {
  const selections = useMemo(() => new Set<AssignmentPriority>(selected), [selected]);
  const priorityOptions = useAssignmentPriorityOptions();

  const handleOnSelect = useEvent((values?: Array<string>) => {
    const matchingItems = new Array<TFacetedFilterOption<AssignmentPriority>>();

    selections.clear();

    for (const value of values ?? []) {
      const matchingItem = value ? priorityOptions?.find((assignmentType) => assignmentType.value === value) : null;

      if (!matchingItem) {
        continue;
      }

      matchingItems.push(matchingItem);
      selections.add(matchingItem.value);
    }

    onSelection?.(Array.from(selections));
    onAssignmentStatesSelection?.(matchingItems);
  });

  const handleOnReset = () => {
    onResetFilter?.();
    selections.clear();
  };

  return (
    <FacetedFilter
      buttonClassName={buttonClassName}
      onResetFilter={handleOnReset}
      onSelectionUpdate={handleOnSelect}
      options={priorityOptions}
      selected={selections}
      title={placeholder}
    />
  );
}
