import * as RToast from '@radix-ui/react-toast';
import React from 'react';
import { ToastAction } from './toast-action';
import { ToastClose } from './toast-close';
import { ToastTitle } from './toast-title';
import { ToastDescription } from './toast-description';
import { ToastRoot } from './toast-root';
import { ToastViewport } from './toast-viewport';

export type ToastProps = React.ComponentPropsWithoutRef<typeof ToastRoot>;
export type ToastActionElement = React.ReactElement<typeof ToastAction>;

export const Toast = {
  Action: ToastAction,
  Close: ToastClose,
  Description: ToastDescription,
  Provider: RToast.Provider,
  Root: ToastRoot,
  Title: ToastTitle,
  Viewport: ToastViewport,
};
