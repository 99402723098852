import { cn } from '@/_shared/utils';
import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';

export const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof RNavigationMenu.List>,
  React.ComponentPropsWithoutRef<typeof RNavigationMenu.List>
>(({ className, ...props }, ref) => (
  <RNavigationMenu.List
    className={cn('group flex flex-1 list-none items-center justify-center gap-2', className)}
    ref={ref}
    {...props}
  />
));

NavigationMenuList.displayName = RNavigationMenu.List.displayName;
