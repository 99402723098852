export enum CustomerFeature {
  ADDRESSATION = 'ADDRESSATION',
  ALL_ASSIGNMENTS = 'ALL_ASSIGNMENTS',
  APP_CREATE_ORDER_MANUAL = 'APP_CREATE_ORDER_MANUAL',
  ASSIGNMENT_CREATE_FORM = 'ASSIGNMENT_CREATE_FORM',
  CREATE_ASSIGNMENT_DECIMAL_QUANTITY = 'CREATE_ASSIGNMENT_DECIMAL_QUANTITY',
  CAN_USE_ASSIGNMENTS = 'CAN_USE_ASSIGNMENTS',
  DASHBOARD_CREATE_ASSIGNMENTS_OF_ORDERS = 'DASHBOARD_CREATE_ASSIGNMENTS_OF_ORDERS',
  DASHBOARD_CREATE_ORDER = 'DASHBOARD_CREATE_ORDER',
  DASHBOARD_EDIT_ORDER = 'DASHBOARD_EDIT_ORDER',
  GLOBAL_FILTER = 'GLOBAL_FILTER',
  INTEGRATION_1C = 'INTEGRATION_1C',
  METRICS_STORE = 'METRICS_STORE',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ORDERS = 'ORDERS',
  PRODUCTS = 'PRODUCTS',
  SETTINGS = 'SETTINGS',
  SHOW_PRODUCT_PRICE_TYPES = 'SHOW_PRODUCT_PRICE_TYPES',
  SKIP_APP_ONBOARDING = 'SKIP_APP_ONBOARDING',
  SSRS_REPORTS = 'SSRS_REPORTS',
  STOCK_CONTROL = 'STOCK_CONTROL',
  STOCK_CONTROL_IMPORT_PRODUCT_STOCKS = 'STOCK_CONTROL_IMPORT_PRODUCT_STOCKS',
  STOCK_CONTORL_EXPORT_REPORT = 'STOCK_CONTORL_EXPORT_REPORT',
  STOCK_MOVEMENT = 'STOCK_MOVEMENT',
  STOCK_REPORT_DECIMAL = 'STOCK_REPORT_DECIMAL',
  VIEW_USER_CARD = 'VIEW_USER_CARD',
  CREATE_PRODUCT_FROM_DASHBOARD = 'CREATE_PRODUCT_FROM_DASHBOARD',
}
