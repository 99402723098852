import { Checkbox, FormField } from '@/_shared/components/atoms';
import { useLazyRef } from '@/_shared/hooks';
import { cn } from '@/_shared/utils';
import { CheckedState } from '@radix-ui/react-checkbox';
import get from 'lodash.get';
import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { FieldPathValue, Path, PathValue, useController } from 'react-hook-form';
import { FormDisabledContext } from './context';

type BooleanFieldValue<T extends Record<string, any>, P extends Path<T>> = PathValue<T, P> extends boolean
  ? FieldPathValue<T, P>
  : never;

type TFormCheckboxFieldProps<T extends Record<string, any>> = {
  allowUnregister?: boolean;
  className?: string;
  inputClassName?: string;
  label: string;
  name: Path<T>;
  onChange?: (value: BooleanFieldValue<T, Path<T>>) => void;
  subLabel?: string;
  wrapperClassName?: string;
} & Omit<React.ComponentPropsWithoutRef<typeof Checkbox>, 'name'>;

export function FormCheckboxField<T extends Record<string, any>>({
  allowUnregister,
  className,
  disabled,
  inputClassName,
  label,
  name,
  onBlur,
  onChange,
  subLabel,
  wrapperClassName,
  ...inputProps
}: TFormCheckboxFieldProps<T>): JSX.Element {
  const disabledContext = useContext(FormDisabledContext);
  const fieldForIdRef = useLazyRef(nanoid);
  const { field, formState } = useController<T, Path<T>>({ name, shouldUnregister: allowUnregister });
  const isInputDisabled = disabled || formState.isSubmitting || formState.isLoading || !!disabledContext;
  const fieldError = get(formState.errors, name);
  const errorMessage = fieldError?.message as string | undefined;

  const handleOnCheckedChange = (state: CheckedState) => {
    const value = (typeof state === 'boolean' ? state : false) as BooleanFieldValue<T, Path<T>>;

    field.onChange?.(value);
    onChange?.(value);
  };

  return (
    <div className={cn('flex items-center justify-start gap-2', wrapperClassName)}>
      <FormField
        className={cn('flex-row gap-2 pb-0', className)}
        errorMessage={errorMessage}
        label={label}
        labelClassName="w-auto"
        name={name}
        reverseLabelChildOrder
      >
        <Checkbox
          className={inputClassName}
          checked={field.value}
          disabled={isInputDisabled}
          id={fieldForIdRef.current}
          name={field.name}
          onBlur={onBlur}
          onCheckedChange={handleOnCheckedChange}
          ref={field.ref}
          value={field.value}
          {...inputProps}
        />
      </FormField>
    </div>
  );
}
