import { reactLazyComponent, sentryCreateBrowserRouter } from '@/_shared/utils';
import { ErrorBoundary } from '@sentry/react';
import { nanoid } from 'nanoid';
import { Navigate, RouteObject } from 'react-router-dom';

const routes: Array<RouteObject> = [
  {
    element: <Navigate to="/login" />,
    path: '/',
  },
  {
    element: reactLazyComponent(() => import('@/auth/components/login'), 'Login', true),
    path: 'login',
  },
  {
    element: reactLazyComponent(() => import('@/dashboard/components/dashboard-root'), 'DashboardRoot', true),
    path: 'dashboard/*',
  },
  {
    element: <Navigate to="/dashboard" />,
    path: '*',
  },
];

export const router = sentryCreateBrowserRouter(
  routes.map((route: RouteObject) => {
    route.ErrorBoundary = ErrorBoundary;
    route.id = nanoid();

    return route;
  }),
);
