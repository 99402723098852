import { FacetedFilter, TFacetedFilterOption } from '@/_shared/components/organisms/faceted-filter';
import { CustomerFeature, OrderStatus, PermissionSlug } from '@/_shared/enums';
import { useAvailableCustomerFeatures } from '@/_shared/hooks';
import { Column } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEvent } from 'react-use-event-hook';

const orderStates: Array<{ status: OrderStatus; customerFeature?: CustomerFeature; permission?: PermissionSlug }> = [
  { customerFeature: CustomerFeature.APP_CREATE_ORDER_MANUAL, status: OrderStatus.OPEN },
  { customerFeature: CustomerFeature.APP_CREATE_ORDER_MANUAL, status: OrderStatus.CANCELED },
  { customerFeature: CustomerFeature.APP_CREATE_ORDER_MANUAL, status: OrderStatus.SYNCED_TO_CLIENT },
  { customerFeature: CustomerFeature.APP_CREATE_ORDER_MANUAL, status: OrderStatus.ON_REALIZATION },
  // { customerFeature: CustomerFeature.APP_CREATE_ORDER_MANUAL, status: OrderStatus.PAID },
  { customerFeature: CustomerFeature.DASHBOARD_CREATE_ORDER, status: OrderStatus.NEW },
  { customerFeature: CustomerFeature.DASHBOARD_CREATE_ORDER, status: OrderStatus.IN_PROGRESS },
  { customerFeature: CustomerFeature.DASHBOARD_CREATE_ORDER, status: OrderStatus.IN_DEPOSIT },
  { customerFeature: CustomerFeature.DASHBOARD_CREATE_ORDER, status: OrderStatus.IN_DEPOSIT_CHECKED },
  { customerFeature: CustomerFeature.DASHBOARD_CREATE_ORDER, status: OrderStatus.FINISHED },
];

function useOrderStateOptions(): Array<TFacetedFilterOption<OrderStatus>> {
  const { t } = useTranslation('warehouse_orders');
  const customerFeatures = useMemo(
    () => Array.from(new Set(orderStates.map(({ customerFeature }) => customerFeature))).filter((x) => !!x),
    [],
  );
  const availableCustomerFeatures = useAvailableCustomerFeatures(customerFeatures);
  const availableOrderStates = orderStates.filter((orderStatus) => {
    return !orderStatus.customerFeature || availableCustomerFeatures.includes(orderStatus.customerFeature);
  });

  return availableOrderStates.map(({ status }) => ({ label: t(`order_status.${status}`) ?? '', value: status }));
}

type TFacetedOrderStateFilterProps = {
  buttonClassName?: string;
  column?: Column<any, any>;
  onOrderStatesSelection?: (states: Array<TFacetedFilterOption<OrderStatus>>) => void;
  onResetFilter?: VoidFunction;
  onSelection?: (values: Array<OrderStatus>) => void;
  placeholder?: string;
  selected?: Array<OrderStatus>;
};

export function FacetedOrderStateFilter({
  buttonClassName,
  placeholder = 'Статус',
  onOrderStatesSelection,
  onResetFilter,
  onSelection,
  selected,
}: TFacetedOrderStateFilterProps): JSX.Element {
  const selections = useMemo(() => new Set<OrderStatus>(selected), [selected]);
  const stateOptions = useOrderStateOptions();

  const handleOnSelect = useEvent((values?: Array<string>) => {
    const matchingItems = new Array<TFacetedFilterOption<OrderStatus>>();

    selections.clear();

    for (const value of values ?? []) {
      const matchingItem = value ? stateOptions?.find((assignmentType) => assignmentType.value === value) : null;

      if (!matchingItem) {
        continue;
      }

      matchingItems.push(matchingItem);
      selections.add(matchingItem.value);
    }

    onSelection?.(Array.from(selections));
    onOrderStatesSelection?.(matchingItems);
  });

  const handleOnReset = () => {
    onResetFilter?.();
    selections.clear();
  };

  return (
    <FacetedFilter
      buttonClassName={buttonClassName}
      onResetFilter={handleOnReset}
      onSelectionUpdate={handleOnSelect}
      options={stateOptions}
      selected={selections}
      title={placeholder}
    />
  );
}
