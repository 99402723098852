import ky, { HTTPError, type Options, type SearchParamsOption } from 'ky';
import { v4 as guid } from 'uuid';
import { getResolvedI18nLanguage } from '../utils/i18n';

function clearMutableSearchParams(params: SearchParamsOption): void {
  if (params === undefined || typeof params === 'string' || params instanceof URLSearchParams) {
    return;
  }

  if (Array.isArray(params)) {
    for (const key in params) {
      if (params[key] === undefined) {
        delete params[key];
      }
    }
  } else {
    for (const key in params) {
      if (params[key] === undefined) {
        delete params[key];
      }
    }
  }
}

type TResponseSuccess<T> = {
  is_success: true;
  data: T;
};

type TResponseErrorInfo = {
  code: string;
  kind: 'validation' | 'internal' | 'network';
  message: string;
};

type TResponseError = {
  error: TResponseErrorInfo;
  is_success: false;
};

const NON_NETWORK_SERVER_ERROR_CODES = [400, 500];

export class ApiResponseError extends Error {
  code: string;
  kind: 'validation' | 'internal' | 'network';

  constructor({ code, kind, message }: TResponseErrorInfo, cause?: Error) {
    super(message, cause ? { cause } : undefined);

    this.code = code;
    this.kind = kind;
  }

  toServerError(): string {
    if (this.kind === 'network') {
      return 'Ошибка сети';
    }

    if (this.kind === 'validation') {
      return this.message;
    }

    return 'Ошибка сервера';
  }
}

export type TResponse<T> = TResponseSuccess<T> | TResponseError;

export class BaseTransport {
  protected ky: typeof ky;

  private processResponse<Response>(response: TResponse<Response>, error?: Error): Response {
    if (!response.is_success) {
      throw new ApiResponseError(response.error, error);
    }

    return response.data;
  }

  private async wrapRequest<Response>(requestCallback: () => Promise<TResponse<Response>>): Promise<Response> {
    try {
      const response = await requestCallback();

      return this.processResponse(response);
    } catch (error) {
      if (error instanceof HTTPError) {
        if (NON_NETWORK_SERVER_ERROR_CODES.includes(error.response.status)) {
          const response: TResponse<Response> = await error.response.json();

          return this.processResponse(response, error);
        }

        throw new ApiResponseError(
          {
            code: 'bad_network',
            kind: 'network',
            message: 'Ошибка сети',
          },
          error,
        );
      } else if (error instanceof TypeError && !navigator.onLine) {
        throw new ApiResponseError(
          {
            code: 'bad_network',
            kind: 'network',
            message: 'Ошибка сети',
          },
          error,
        );
      } else {
        // NOTE: отмена запроса не считается ошибкой, поэтому просто ужимаем её в одну строку предупреждения
        if (!(error instanceof DOMException && error.message.toLowerCase().includes('aborted'))) {
          console.error('Unexpected error', error);
        } else {
          console.warn(error.message);
        }

        throw error;
      }
    }
  }

  constructor() {
    this.ky = ky.create({
      prefixUrl: '/api',
    });
  }

  private buildRequestOptions(options?: Options): Options {
    const headers = Object.assign(options?.headers ?? {}, {
      'x-appsession-id': window.APP_SESSION_ID,
      'x-request-channel': 'dashboard',
      'x-request-id': guid(),
      'x-request-timestamp': new Date().toISOString(),
      'x-language-name': getResolvedI18nLanguage(),
    });

    return Object.assign(options ?? {}, {
      headers,
    });
  }

  protected async get<Response>(path: string, options?: Options): Promise<Response> {
    clearMutableSearchParams(options?.searchParams);

    return this.wrapRequest(() => this.ky.get(path, this.buildRequestOptions(options)).json<TResponse<Response>>());
  }

  protected async delete<Response>(path: string, options?: Options): Promise<Response> {
    clearMutableSearchParams(options?.searchParams);

    return this.wrapRequest(() => this.ky.delete(path, this.buildRequestOptions(options)).json<TResponse<Response>>());
  }

  protected async put<Request, Response>(
    path: string,
    body: Request,
    options?: Omit<Options, 'json'>,
  ): Promise<Response> {
    return this.wrapRequest(() =>
      this.ky
        .put(path, Object.assign({ json: body }, this.buildRequestOptions(options) ?? {}))
        .json<TResponse<Response>>(),
    );
  }

  protected async post<Request, Response>(
    path: string,
    body: Request,
    options?: Omit<Options, 'json'>,
  ): Promise<Response> {
    return this.wrapRequest(() =>
      this.ky
        .post(path, Object.assign({ json: body }, this.buildRequestOptions(options) ?? {}))
        .json<TResponse<Response>>(),
    );
  }

  protected async postFormData<Response>(
    path: string,
    body: FormData,
    options?: Omit<Options, 'body' | 'json'>,
  ): Promise<Response> {
    return this.wrapRequest(() =>
      this.ky.post(path, Object.assign({ body }, options ?? {})).json<TResponse<Response>>(),
    );
  }
}
