import { useUser } from '@/_shared/hooks/use-user';
import { customerTransport } from '@/_shared/transports';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';

type TUseCustomerInfoQueryKey = ['get-customer-info', string | null | undefined];

const fetchUsers = async (context: QueryFunctionContext<TUseCustomerInfoQueryKey>) => {
  return await customerTransport.getCustomerInfo({ signal: context.signal });
};

export function useCustomerInfo() {
  const loggedInUser = useUser();

  return useQuery({
    meta: {
      errorDescription: 'Не удалось получить информацию о клиенте',
    },
    queryFn: fetchUsers,
    queryKey: ['get-customer-info', loggedInUser?.id] satisfies TUseCustomerInfoQueryKey,
  });
}
