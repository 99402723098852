export enum AssignmentState {
  /**
   * Таск создан в очередь, ждёт пока прийдёт его черёд
   */
  AWAITS_SELECTION = 'AWAITS_SELECTION',
  /**
   * Таск выдан рабочему, ожидается что он идёт его делать
   */
  IN_PROGRESS = 'IN_PROGRESS',
  /**
   * Рабочий приостоновил выполнение таска
   */
  ON_HOLD = 'ON_HOLD',
  /**
   * Рабочий закончил выполнение таска
   */
  COMPLETED = 'COMPLETED',
  /**
   * Рабочий закончил выполнение таска, но с ошибками
   * Нужна интервенция менеджера, чтобы разобраться с последствиями
   */
  COMPLETED_WITH_ERRORS = 'COMPLETED_WITH_ERRORS',
  /**
   * Менеджер закрыл задание без завершения
   */
  CLOSED = 'CLOSED',
}

export enum AssignmentPriority {
  HIGHEST = 'HIGHEST',
  HIGH = 'HIGH',
  NORMAL = 'NORMAL',
  LOW = 'LOW',
}

export enum AssignmentTaskState {
  /**
   * Произошла ошибка при выполнении задачи, пропущено с примечанием
   */
  ERROR = 'ERROR',
  /**
   * Задача выполнена
   */
  FINISHED = 'FINISHED',
  /**
   * Задача заведена, готова к выполнению
   */
  NEW = 'NEW',
}

export enum AssignmentTaskType {
  COMMON = 'COMMON',
  CONCRETE = 'CONCRETE',
}

export enum AssignmentTypeFieldRequirement {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL',
  NOT_NEEDED = 'NOT_NEEDED',
}

export enum AssignmentTypeCategorySlugRequirement {
  STOCK_MOVEMENTS = 'STOCK_MOVEMENTS',
  STOCK_CHECKS = 'STOCK_CHECKS',
}
