import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export type TAppVersion = {
  id: string;
  version: string;
  uploaded_at?: string;
  uploaded_file_id: string;
  uploader_name: string;
};

type TGetAllVersionsResponse = {
  app_versions: Array<TAppVersion>;
};

type TUploadAppVersionRequest = {
  app_version: string;
};
type TUploadAppVersionResponse = {
  uploaded_app_version: TAppVersion;
};

class WorkerAppTransport extends BaseTransport {
  async getAllVersions(options?: Omit<Options, 'json'>): Promise<Array<TAppVersion>> {
    const response = await this.get<TGetAllVersionsResponse>('worker-app/get-all-versions', options);

    return response.app_versions;
  }

  async uploadAppVersion(
    formData: FormData,
    searchParams: TUploadAppVersionRequest,
    options?: Omit<Options, 'json' | 'searchParams'>,
  ): Promise<TAppVersion> {
    const response = await this.postFormData<TUploadAppVersionResponse>(
      'worker-app/upload-app-version',
      formData,
      Object.assign(options ?? {}, { searchParams }),
    );

    return response.uploaded_app_version;
  }
}

export const workerAppTransport = new WorkerAppTransport();
