import { FormField, Select } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import { useContext } from 'react';
import { FieldPath, FieldPathValue, FieldValues, useController, useFormContext } from 'react-hook-form';
import { useEvent } from 'react-use-event-hook';
import { FormDisabledContext } from '../context';
import { TOption } from './types';

export type TFormSelectFieldProps<
  T extends FieldValues,
  K extends FieldPath<T>,
  V extends FieldPathValue<T, K>,
  O extends TOption<V, LK, VK>,
  LK extends string = 'label',
  VK extends string = 'value',
> = {
  allowUnregister?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  labelKey?: LK;
  name: K;
  options: Array<O>;
  placeholder?: string;
  triggerClassName?: string;
  saveAsOption?: boolean;
  valueKey?: VK;
  onSelect?: (value: O) => void;
};

export function FormSelectField<
  T extends FieldValues,
  K extends FieldPath<T>,
  V extends FieldPathValue<T, K>,
  O extends TOption<V, LK, VK>,
  LK extends string = 'label',
  VK extends string = 'value',
>({
  allowUnregister,
  className,
  disabled,
  label,
  labelKey,
  name,
  options,
  placeholder = '- Не выбрано -',
  saveAsOption,
  triggerClassName,
  valueKey,
  onSelect
}: TFormSelectFieldProps<T, K, V, O, LK, VK>): JSX.Element {
  const disabledContext = useContext(FormDisabledContext);
  const labelKeyGuaranteed = (labelKey ?? 'label') as LK;
  const valueKeyGuaranteed = (valueKey ?? 'value') as VK;

  const methods = useFormContext<T>();
  const fieldProps = useController<T, K>({
    name,
    shouldUnregister: allowUnregister,
  });
  const isFieldDisabled =
    disabled || methods.formState.isSubmitting || methods.formState.isLoading || !!disabledContext;
  const errorMessage = fieldProps.fieldState.error?.message as string | undefined;
  const shouldErrorBeShown = fieldProps.formState.isSubmitted || fieldProps.fieldState.isTouched;
  const selectTriggerClassName = cn('z-10', triggerClassName);

  const handleOnValueChange = useEvent((value: string) => {
    const optionValue = options.find((option) => option[valueKeyGuaranteed] === value);
    const saveValue = saveAsOption ? optionValue : value;
    fieldProps.field.onChange(saveValue as V);
    onSelect?.(optionValue as O);
  });

  return (
    <FormField
      className={className}
      errorMessage={shouldErrorBeShown ? errorMessage : undefined}
      label={label}
      name={name}
      onBlur={fieldProps.field.onBlur}
      useWrapper
    >
      <Select.Root
        defaultValue={fieldProps.field.value ?? undefined}
        disabled={isFieldDisabled}
        onValueChange={handleOnValueChange}
      >
        <Select.Trigger className={selectTriggerClassName}>
          <Select.Value placeholder={placeholder} />
        </Select.Trigger>

        <Select.Content className="w-full shadow" data-state="open">
          {options.map((option) => (
            <Select.Item
              className="w-full"
              icon={option.icon}
              key={option[valueKeyGuaranteed]}
              value={option[valueKeyGuaranteed]}
            >
              <span className="w-full">{option[labelKeyGuaranteed]}</span>
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </FormField>
  );
}
