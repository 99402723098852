export enum PermissionSlug {
  /**
   * Администратор Monsato
   */
  Admin = 'admin',

  /**
   * Auth - Worker App
   */
  LoginWorkerApp = 'login-worker-app',

  /**
   * Auth - Manager Dashboard
   */
  LoginManagerDashboard = 'login-manager-dashboard',
  GetUsers = 'get-users',

  /**
   * Auth - Manager Dashboard (as Director)
   */
  RegisterUser = 'register-user',
  ManageRoles = 'manage-roles',
  ResetUserPassword = 'reset-user-password',
  ResetUserToken = 'reset-user-token',
  PrintWorkerAppBadge = 'print-worker-app-badge',

  /**
   * Assignments & Tasks
   */
  GetNextTask = 'get-next-task',
  GetNextTaskWithType = 'get-next-task-with-type',
  FinishTask = 'finish-task',

  /**
   * Assignments & Tasks (dashboard)
   */
  GetTaskQueue = 'get-task-queue',
  AddTaskToQueue = 'add-task-to-queue',
  ForceFinishTask = 'force-finish-task',
  AssignAssignmentWorker = 'assign-assignment-worker',
  DeassignAssignmentWorker = 'deassign-assignment-worker',

  /**
   * Scanner
   */
  ScanShowsProductDetails = 'scan-shows-product-details',
  ScannerShowsSku = 'scanner-shows-sku',
  ScannerShowsExpectedQuantity = 'scanner-shows-expected-quantity',

  /**
   * Locations
   */
  ManageLocations = 'manage-locations',

  /**
   * Orders
   */
  OrderManage = 'order-manage',
  OrderShowAll = 'order-show-all',

  OrderAccessToBuildingIds = 'order-access-to-building-ids',
  OrderAccessToAssignedUserIds = 'order-access-to-assigned-user-ids',

  OrderCreateDraft = 'create-order-draft',
  CreateAssignmentByOrder = 'create-assignment-by-order',
  CreateDashboardOrder = 'create-dashboard-order',

  /**
   * Products
   */
  ManageProducts = 'manage-products',
  CreateProductsFromDashboard = 'create-product-from-dashboard',
  UpdateProductsFromDashboard = 'update-product-from-dashboard',
  DeleteProductsFromDashboard = 'delete-product-from-dashboard',

  /**
   * Storage Cells
   */
  ManageStorageCells = 'manage-storage-cells',

  /**
   * Manual Stock Control
   */
  ManualStockControl = 'manual-stock-control',

  /**
   * Metrics
   */
  ViewMetricsAllLocations = 'view-metrics-all-locations',
  ViewMetricsStore = 'view-metrics-store',
  ViewMetricsWarehouse = 'view-metrics-warehouse',
}
