import { useObservable } from '@/_shared/hooks/use-observable';
import { globalFilterService } from '@/warehouse/services/global-filter.service';
import { useMemo } from 'react';

export function useGlobalFilters(propertyWhitelist: Array<string>) {
  const globalFilters = useObservable(globalFilterService.filters$, globalFilterService.value);

  return useMemo(
    () =>
      propertyWhitelist.reduce(
        (acc, key) => {
          acc[key] = globalFilters[key];

          return acc;
        },
        {} as Record<string, any>,
      ),
    [globalFilters, propertyWhitelist],
  );
}
