import { Options } from 'ky';
import {
  TCreateContainersRequest,
  TCreateContainersResponse,
  TDeleteContainersRequest,
  TDeleteContainersResponse,
  TEditContainerRequest,
  TEditContainerResponse,
  TFindContainersRequest,
  TFindContainersResponse,
  TGetContainerByIdContainerRequest,
  TGetContainerByIdContainerResponse,
} from '../types';
import { BaseTransport } from './base.transport';

class ContainerTransport extends BaseTransport {
  async createContainers(
    dto: TCreateContainersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TCreateContainersResponse> {
    return await this.post<TCreateContainersRequest, TCreateContainersResponse>('container/create', dto, options);
  }

  async deleteContainers(
    dto: TDeleteContainersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TDeleteContainersResponse> {
    return await this.post<TDeleteContainersRequest, TDeleteContainersResponse>('container/delete', dto, options);
  }

  async editContainer(dto: TEditContainerRequest, options?: Omit<Options, 'json'>): Promise<TEditContainerResponse> {
    return await this.post<TEditContainerRequest, TEditContainerResponse>('container/edit', dto, options);
  }

  async findContainers(dto: TFindContainersRequest, options?: Omit<Options, 'json'>): Promise<TFindContainersResponse> {
    return await this.post<TFindContainersRequest, TFindContainersResponse>('container/find', dto, options);
  }

  async getById(
    dto: TGetContainerByIdContainerRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TGetContainerByIdContainerResponse> {
    return await this.post<TGetContainerByIdContainerRequest, TGetContainerByIdContainerResponse>(
      'container/get-by-id',
      dto,
      options,
    );
  }
}

export const containerTransport = new ContainerTransport();
