import { useState } from 'react';

export function useCounter(initialValue: number = 0) {
  const [count, setCount] = useState(initialValue);

  return {
    decrease: () => setCount((value) => value - 1),
    increase: () => setCount((value) => value + 1),
    value: count,
  };
}
