import { DropdownMenu } from '@/_shared/components/atoms/dropdown-menu';
import { Input } from '@/_shared/components/atoms/input';
import { cn } from '@/_shared/utils';
import * as RPopper from '@radix-ui/react-popper';
import { Check, ChevronDown, Loader2 } from 'lucide-react';
import React, { FocusEvent, ForwardedRef, MutableRefObject, useCallback, useId, useRef, useState } from 'react';
import { useEvent } from 'react-use-event-hook';

function mergeRefs<T extends HTMLElement | null>(...refs: Array<MutableRefObject<T> | ForwardedRef<T>>) {
  return (node: T): void => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
    }
  };
}

type TComboboxState = {
  defaultValue?: string;
  selectedValue?: string;
  selectedValueLabel?: string;
};

export type TComboboxContext = {
  onItemBlur: React.FocusEventHandler<HTMLButtonElement>;
  onItemClick: React.MouseEventHandler<HTMLButtonElement>;
  state: TComboboxState;
  updateState: (state: Partial<TComboboxState>) => void;
};

export const ComboboxContext = React.createContext<TComboboxContext>({
  onItemBlur: () => ({}),
  onItemClick: () => ({}),
  state: {
    defaultValue: undefined,
    selectedValue: undefined,
    selectedValueLabel: undefined,
  },
  updateState: () => ({}),
});

type TComboboxProps = {
  allowResetting?: boolean;
  buttonClassName?: string;
  children: React.ReactNode;
  className?: string;
  contextRef?: MutableRefObject<TComboboxContext | undefined>;
  defaultValue?: string;
  disabled?: boolean;
  emptyPlaceholder?: string;
  isLoading?: boolean;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onDropdownStateChange?: (isOpened: boolean) => void;
  onInputChange?: React.ChangeEventHandler<HTMLInputElement>;
  onSelect?: (value: string | null) => void;
} & Omit<React.ComponentPropsWithoutRef<'input'>, 'onBlur' | 'onChange' | 'onFocus' | 'onSelect'>;

const ComboboxRoot = React.forwardRef<HTMLInputElement, TComboboxProps>(
  (
    {
      allowResetting,
      buttonClassName,
      children,
      className,
      defaultValue,
      disabled,
      emptyPlaceholder,
      isLoading,
      onBlur,
      onDropdownStateChange,
      onInputChange,
      onSelect,
      contextRef,
      ...inputProps
    },
    ref,
  ) => {
    const comboboxId = useId();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const updateDropdownState = useEvent((isOpened: boolean) => {
      setIsDropdownOpen(isOpened);
      onDropdownStateChange?.(isOpened);
    });

    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const contextInnerRef = useRef<TComboboxContext>({
      state: {
        defaultValue,
        selectedValue: undefined,
        selectedValueLabel: undefined,
      },
      onItemBlur: (event) => {
        if (!event.relatedTarget) {
          return;
        }

        const nextTarget = event.relatedTarget as HTMLElement;
        const isComboboxInput = nextTarget.hasAttribute('data-combobox-input');
        const isComboboxElement = isComboboxInput || nextTarget.hasAttribute('data-combobox-item');

        if (!isComboboxElement) {
          updateDropdownState(false);

          if (isComboboxInput) {
            onBlur?.(event);
          }
        }
      },
      onItemClick: (event) => {
        const target = event.currentTarget;
        const label = target.getAttribute('data-label') ?? undefined;
        const value = target.getAttribute('data-value') ?? undefined;

        contextInnerRef.current.updateState({ selectedValue: value, selectedValueLabel: label });

        if (onSelect) {
          // Relay null as intended empty value
          onSelect(value ?? null);
        }

        updateDropdownState(false);

        setTimeout(() => {
          buttonRef?.current?.focus?.();
        }, 0);
      },
      updateState: (state: Partial<TComboboxState>) => {
        contextInnerRef.current.state = {
          ...contextInnerRef.current.state,
          ...state,
        };
      },
    });

    if (contextRef) {
      contextRef.current = contextInnerRef.current;
    }

    const emptyPlaceholderLabel = `- ${emptyPlaceholder ?? 'Не выбрано'} -`;
    const selectedValueLabel = contextInnerRef?.current?.state.selectedValueLabel ?? emptyPlaceholderLabel;
    const anchorClassName = cn(
      'select-none placeholder:text-muted-foreground focus:outline-none focus:data-[is-open=false]:ring-ring focus:ring-offset-2',
      className,
    );

    const handleClickFocus = useCallback(() => {
      if (disabled) {
        return;
      }

      updateDropdownState(true);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }, [disabled, updateDropdownState]);

    const handleInputBlur = useEvent((event: FocusEvent<HTMLInputElement>) => {
      if (!event.relatedTarget) {
        return;
      }

      const nextTarget = event.relatedTarget as HTMLElement;
      const isComboboxElement = nextTarget.hasAttribute('data-combobox-item');

      if (!isComboboxElement) {
        updateDropdownState(false);
        onBlur?.(event);
      }
    });

    const handleInputClick = useEvent(() => {
      updateDropdownState(true);
    });

    return (
      <RPopper.Root>
        <ComboboxContext.Provider value={contextInnerRef.current}>
          <RPopper.Anchor
            className={anchorClassName}
            data-is-open={isDropdownOpen}
            onBlur={handleInputBlur}
            onClick={handleInputClick}
          >
            <>
              <ComboboxInput
                key={comboboxId}
                isDropdownOpen={isDropdownOpen}
                isLoading={isLoading}
                onChange={onInputChange}
                ref={mergeRefs(ref, inputRef)}
                {...inputProps}
              />

              {!isDropdownOpen && (
                <button
                  className={cn(
                    'flex h-10 w-full items-center rounded-md border px-3 py-2 text-start text-sm ring-offset-background focus:z-10 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
                    buttonClassName,
                  )}
                  disabled={disabled}
                  onClick={handleClickFocus}
                  ref={buttonRef}
                >
                  <span className="flex-1">{selectedValueLabel}</span>
                  <ChevronDown className="ml-2 inline-block h-4 w-4 opacity-50" />
                </button>
              )}
            </>
          </RPopper.Anchor>

          {isDropdownOpen && React.Children.count(children) > 0 && (
            <RPopper.Content
              className="z-50 mt-2 max-h-64 min-w-[16rem] overflow-y-auto rounded border bg-white p-1 shadow"
              align="start"
              style={{ transformOrigin: 'var(--radix-popper-transform-origin)' }}
            >
              {allowResetting && <ComboboxItem>{emptyPlaceholderLabel}</ComboboxItem>}

              {children}
            </RPopper.Content>
          )}
        </ComboboxContext.Provider>
      </RPopper.Root>
    );
  },
);

ComboboxRoot.displayName = 'ComboboxRoot';

type TComboboxInputProps = Omit<React.ComponentPropsWithoutRef<'input'>, 'onBlur' | 'onFocus' | 'onSelect'> & {
  isDropdownOpen: boolean;
  isLoading?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const ComboboxInput = React.forwardRef<HTMLInputElement, TComboboxInputProps>(
  ({ isDropdownOpen, isLoading, ...inputProps }, ref) => {
    const wrapperClassName = cn('relative', { 'force-hide': !isDropdownOpen });

    return (
      <div className={wrapperClassName}>
        <Input
          autoComplete="off"
          data-combobox-input="true"
          disabled={!isDropdownOpen}
          ref={ref}
          tabIndex={isDropdownOpen ? 0 : -1}
          type="search"
          {...inputProps}
        />

        {isLoading && (
          <Loader2 className="pointer-events-none absolute right-[0.5625rem] top-[0.5625rem] animate-spin" />
        )}
      </div>
    );
  },
);

ComboboxInput.displayName = 'ComboboxInput';

type TComboboxItemProps = {
  children: string;
  shortcut?: string;
  value?: string;
};

const ComboboxItem = React.forwardRef<HTMLButtonElement, TComboboxItemProps>(({ children, shortcut, value }, ref) => {
  const context = React.useContext(ComboboxContext);
  const isSelected = context.state.selectedValue === value;
  const className = cn(
    'relative block w-full text-start rounded-sm text-sm z-20 pl-8 pr-2 py-1 bg-white hover:cursor-pointer hover:bg-slate-200 active:bg-slate-300 focus:outline-none focus:bg-accent',
    {
      'bg-accent': isSelected,
    },
  );

  return (
    <button
      className={className}
      data-combobox-item="true"
      data-label={children}
      data-value={value}
      onBlur={context.onItemBlur}
      onClick={context.onItemClick}
      ref={ref}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        {isSelected && <Check className="h-4 w-4" />}
      </span>

      <span>{children}</span>
      <DropdownMenu.Shortcut>{shortcut}</DropdownMenu.Shortcut>
    </button>
  );
});

ComboboxItem.displayName = 'ComboboxItem';

export const Combobox = {
  Item: ComboboxItem,
  Root: ComboboxRoot,
};
