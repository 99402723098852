//import * as RAvatar from '@radix-ui/react-avatar';
import { cn } from '@/_shared/utils/classnames';
import * as React from 'react';

export const TableRow = React.forwardRef<HTMLTableRowElement, React.HTMLAttributes<HTMLTableRowElement>>(
  ({ className, ...props }, ref) => (
    <tr
      ref={ref}
      className={cn('border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted', className)}
      {...props}
    />
  ),
);

TableRow.displayName = 'TableRow';
