import { cn } from '@/_shared/utils';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';

export const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.Label>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.Label> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <RDropdownMenu.Label
    ref={ref}
    className={cn('px-2 py-1.5 text-sm font-semibold', inset && 'pl-8', className)}
    {...props}
  />
));

DropdownMenuLabel.displayName = RDropdownMenu.Label.displayName;
