import { Input } from '@/_shared/components/atoms';
import { cn } from '@/_shared/utils';
import React from 'react';

type TSelectInputProps = React.ComponentPropsWithoutRef<typeof Input>;

export const SelectInput = React.forwardRef<HTMLInputElement, TSelectInputProps>(({ className, ...props }, ref) => {
  return <Input className={cn('-mx-1 my-1 h-px bg-muted', className)} ref={ref} {...props} />;
});

SelectInput.displayName = 'SelectInput';
