import { BehaviorSubject, Observable, identity } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

const DEBOUNCE_DELAY: number = 500;

export type TInitialStateFn<T extends object> = T | (() => T);
export type TApplyFn<T extends object> = (state: T) => any;
export type TPartialUpdateFn<T extends object> = Partial<T> | ((state: T) => Partial<T>);

export class FilterService<T extends object = object> {
  private readonly initialFilter: TInitialStateFn<T>;

  private filterSubject$: BehaviorSubject<T>;

  filters$: Observable<T>;
  filtersDirect$: Observable<T>;

  protected constructor(initialFilter: TInitialStateFn<T>) {
    const parsedSearchParams = this.parseSearchParams(new URLSearchParams(window.location.search));

    this.initialFilter = initialFilter;

    this.filterSubject$ = new BehaviorSubject(Object.assign(this.getInitialState(), parsedSearchParams));
    this.filters$ = this.filterSubject$.asObservable().pipe(debounceTime(DEBOUNCE_DELAY));
    this.filtersDirect$ = this.filterSubject$.asObservable();
  }

  private getInitialState(): T {
    return typeof this.initialFilter === 'function' ? this.initialFilter() : structuredClone(this.initialFilter);
  }

  protected executePartialFiltersFn(partialFiltersFn: TPartialUpdateFn<T>): Partial<T> {
    return typeof partialFiltersFn === 'function' ? partialFiltersFn(this.value) : partialFiltersFn;
  }

  apply(applyFn: TApplyFn<T>): void {
    applyFn(this.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parseSearchParams(_searchParams: URLSearchParams): Partial<T> {
    return {};
  }

  resetFilters(): void {
    this.filterSubject$.next(this.getInitialState());
  }

  select<V>(selector: (filterState: T) => V, onlyDistinct: boolean = true): Observable<V> {
    return this.filters$.pipe(map(selector), onlyDistinct ? distinctUntilChanged() : map(identity));
  }

  selectDirect<V>(selector: (filterState: T) => V, onlyDistinct: boolean = true): Observable<V> {
    return this.filtersDirect$.pipe(map(selector), onlyDistinct ? distinctUntilChanged() : map(identity));
  }

  updateFilter(partialFiltersFn: TPartialUpdateFn<T>): void {
    const partialFilters = this.executePartialFiltersFn(partialFiltersFn);
    const currentFilter = Object.assign({}, this.filterSubject$.value, partialFilters);

    this.filterSubject$.next(currentFilter);
  }

  get value(): T {
    return this.filterSubject$.value;
  }
}
