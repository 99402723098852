import { CustomerFeature } from '@/_shared/enums';
import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export type TCustomerInfo = {
  company_name: string;
  features: Array<CustomerFeature>;
};

export type TGetCustomerInfoResponse = {
  customer_info: TCustomerInfo;
};

class CustomerTransport extends BaseTransport {
  async getCustomerInfo(options?: Omit<Options, 'json'>): Promise<TCustomerInfo> {
    const { customer_info } = await this.get<TGetCustomerInfoResponse>('customer/get-customer-info', options);

    return customer_info;
  }
}

export const customerTransport = new CustomerTransport();
