import { OrderStatus, OrderType } from '@/_shared/enums';
import { useTranslation } from 'react-i18next';

export const orderStatusColorClass: Record<OrderStatus, string> = {
  [OrderStatus.CANCELED]: 'text-red-300',
  [OrderStatus.OPEN]: 'text-blue-500',
  [OrderStatus.PAID]: 'text-green-700',
  [OrderStatus.SYNCED_TO_CLIENT]: 'text-purple-500',
  [OrderStatus.ON_REALIZATION]: 'text-green-600',
  [OrderStatus.DRAFT]: 'text-blue-300',
  [OrderStatus.NEW]: 'text-blue-500',
  [OrderStatus.IN_PROGRESS]: 'text-orange-500',
  [OrderStatus.IN_DEPOSIT]: 'text-orange-600',
  [OrderStatus.IN_DEPOSIT_CHECKED]: 'text-green-500',
  [OrderStatus.IN_DEPOSIT_CHECKED_WITH_ERRORS]: 'text-red-500',
  [OrderStatus.FINISHED]: 'text-green-700',
  [OrderStatus.IN_RESERVE]: 'text-blue-700',
};

export const orderStatusBadgeColorClass: Record<OrderStatus, string> = {
  [OrderStatus.CANCELED]: 'bg-red-300 text-white',
  [OrderStatus.OPEN]: 'bg-blue-500 text-white',
  [OrderStatus.PAID]: 'bg-green-700 text-white',
  [OrderStatus.SYNCED_TO_CLIENT]: 'bg-purple-500 text-white',
  [OrderStatus.ON_REALIZATION]: 'bg-green-600 text-white',
  [OrderStatus.DRAFT]: 'bg-blue-300',
  [OrderStatus.NEW]: 'bg-blue-500 text-white',
  [OrderStatus.IN_PROGRESS]: 'bg-orange-500 text-white',
  [OrderStatus.IN_DEPOSIT]: 'bg-orange-600 text-white',
  [OrderStatus.IN_DEPOSIT_CHECKED]: 'bg-green-500 text-white',
  [OrderStatus.IN_DEPOSIT_CHECKED_WITH_ERRORS]: 'bg-red-500 text-white',
  [OrderStatus.FINISHED]: 'bg-green-700 text-white',
  [OrderStatus.IN_RESERVE]: 'bg-blue-700 text-white',
};

export const orderTypeColorClass: Record<OrderType, string> = {
  [OrderType.DELIVERY]: 'text-lime-600',
  [OrderType.MANUAL]: 'text-orange-700',
  [OrderType.PICKUP]: 'text-green-700',
};

export const orderTypeBadgeColorClass: Record<OrderType, string> = {
  [OrderType.DELIVERY]: 'bg-lime-600 text-white',
  [OrderType.MANUAL]: 'bg-orange-700 text-white',
  [OrderType.PICKUP]: 'bg-green-700 text-white',
};

export function useOrderStatusLocalizedName(orderStatuses: OrderStatus): string;
export function useOrderStatusLocalizedName(orderStatuses: Array<OrderStatus>): Array<string>;
export function useOrderStatusLocalizedName(orderStatuses: OrderStatus | Array<OrderStatus>): string | Array<string> {
  const { t } = useTranslation('warehouse_orders');

  if (Array.isArray(orderStatuses)) {
    return orderStatuses.map((orderStatus) => t(`order_status.${orderStatus}`));
  }

  return t(`order_status.${orderStatuses}`);
}

export function useOrderTypeLocalizedName(orderTypes: OrderType): string;
export function useOrderTypeLocalizedName(orderTypes: Array<OrderType>): Array<string>;
export function useOrderTypeLocalizedName(orderTypes: OrderType | Array<OrderType>): string | Array<string> {
  const { t } = useTranslation('warehouse_orders');

  if (Array.isArray(orderTypes)) {
    return orderTypes.map((orderType) => t(`order_type.${orderType}`));
  }

  return t(`order_type.${orderTypes}`);
}
