import { cn } from '@/_shared/utils';
import * as RForm from '@radix-ui/react-form';
import React from 'react';

type TFormMessage = React.ComponentPropsWithoutRef<typeof RForm.Message>;

export function FormMessage({ children, className }: TFormMessage): JSX.Element {
  const messageClassName = cn('text-sm text-rose-500', className);

  return <RForm.Message className={messageClassName}>{children}</RForm.Message>;
}
