import { SimpleTooltip } from '@/_shared/components/atoms/tooltip';
import { cn } from '@/_shared/utils';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';
import { ReactNode } from 'react';

const badgeVariants = cva(
  'inline-flex items-center cursor-default rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:opacity-80',
        secondary: 'border-transparent bg-secondary text-secondary-foreground hover:opacity-80',
        destructive: 'border-transparent bg-destructive text-destructive-foreground hover:opacity-80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  tooltip?: ReactNode;
}

export function Badge({ children, className, tooltip, variant, ...props }: BadgeProps) {
  const badgeClassName = cn(badgeVariants({ variant }), className);
  const badgeColor = badgeClassName.replace(/.*(text-(?:\w+-\d00|white|black)).*/g, '$1');

  return tooltip ? (
    <SimpleTooltip content={tooltip}>
      <div className={badgeClassName} {...props}>
        {children}
        <InfoCircledIcon className={cn('ml-1 h-3 w-3 shrink-0', badgeColor)} />
      </div>
    </SimpleTooltip>
  ) : (
    <div className={badgeClassName} {...props}>
      {children}
    </div>
  );
}
