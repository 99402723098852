import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import { NavigationMenuContent } from './navigation-menu-content';
import { NavigationMenuIndicator } from './navigation-menu-indicator';
import { NavigationMenuList } from './navigation-menu-list';
import { NavigationMenuRoot } from './navigation-menu-root';
import { NavigationMenuTrigger, navigationMenuTriggerStyle } from './navigation-menu-trigger';
import { NavigationMenuViewport } from './navigation-menu-viewport';

export const NavigationMenu = {
  navigationMenuTriggerStyle,
  Content: NavigationMenuContent,
  Indicator: NavigationMenuIndicator,
  Item: RNavigationMenu.Item,
  Link: RNavigationMenu.Link,
  List: NavigationMenuList,
  Root: NavigationMenuRoot,
  Sub: RNavigationMenu.Sub,
  Trigger: NavigationMenuTrigger,
  Viewport: NavigationMenuViewport,
};
