import { cn } from '@/_shared/utils';
import * as RToast from '@radix-ui/react-toast';
import React from 'react';

export const ToastAction = React.forwardRef<
  React.ElementRef<typeof RToast.Action>,
  React.ComponentPropsWithoutRef<typeof RToast.Action>
>(({ className, ...props }, ref) => (
  <RToast.Action
    className={cn(
      'inline-flex h-8 shrink-0 items-center justify-center rounded-md border border-slate-200 bg-transparent px-3 text-sm font-medium transition-colors hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group-[.destructive]:border-red-100 group-[.destructive]:hover:border-slate-50 group-[.destructive]:hover:bg-red-100 group-[.destructive]:hover:text-red-600 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600 dark:border-slate-700 dark:text-slate-100 dark:hover:bg-slate-700 dark:hover:text-slate-100 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900 dark:data-[state=open]:bg-slate-800',
      className,
    )}
    ref={ref}
    {...props}
  />
));

ToastAction.displayName = RToast.Action.displayName;
