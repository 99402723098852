import { cn } from '@/_shared/utils';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';
import { Check } from 'lucide-react';
import React from 'react';

export const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <RDropdownMenu.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    checked={checked}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <RDropdownMenu.ItemIndicator>
        <Check className="h-4 w-4" />
      </RDropdownMenu.ItemIndicator>
    </span>
    {children}
  </RDropdownMenu.CheckboxItem>
));

DropdownMenuCheckboxItem.displayName = RDropdownMenu.CheckboxItem.displayName;
