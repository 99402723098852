import { cn } from '@/_shared/utils';
import * as RSelect from '@radix-ui/react-select';
import { ChevronDown } from 'lucide-react';
import React from 'react';

export const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof RSelect.Trigger>,
  React.ComponentPropsWithoutRef<typeof RSelect.Trigger>
>(({ className, children, ...props }, ref) => (
  <RSelect.Trigger
    className={cn(
      'flex h-10 w-full items-center justify-between rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    ref={ref}
    {...props}
  >
    {children}
    <RSelect.Icon asChild>
      <ChevronDown className="h-4 w-4 opacity-50" />
    </RSelect.Icon>
  </RSelect.Trigger>
));

SelectTrigger.displayName = RSelect.Trigger.displayName;
