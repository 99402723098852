import { RefObject, useRef } from 'react';

interface LazyRefObject<T> extends RefObject<T> {
  readonly current: T;
}

const noneSymbol = Symbol('lazy-ref-none');

export function useLazyRef<T>(initializer: () => T): LazyRefObject<T> {
  const ref = useRef<T | typeof noneSymbol>(noneSymbol);

  if (ref.current === noneSymbol) {
    ref.current = initializer();
  }

  return ref as LazyRefObject<T>;
}
