export enum OrderStatus {
  /* manual */
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  PAID = 'PAID',
  SYNCED_TO_CLIENT = '1C_Android_Order',
  ON_REALIZATION = '1C_Realization',

  /* delivery & pickup */
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_DEPOSIT = 'IN_DEPOSIT',
  IN_RESERVE = 'IN_RESERVE',
  IN_DEPOSIT_CHECKED = 'IN_DEPOSIT_CHECKED',
  IN_DEPOSIT_CHECKED_WITH_ERRORS = 'IN_DEPOSIT_CHECKED_WITH_ERRORS',
  FINISHED = 'FINISHED',
}

export enum OrderType {
  DELIVERY = 'DELIVERY',
  MANUAL = 'MANUAL',
  PICKUP = 'PICKUP',
}
