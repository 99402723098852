import { cn } from '@/_shared/utils';
import React, { HTMLAttributes, useMemo } from 'react';
import { Trans } from 'react-i18next';

type TDialogFooterProps = HTMLAttributes<HTMLDivElement> & {
  serverError?: string;
  serverErrors?: Array<string>;
  wrapperClassName?: string;
};

export const DialogFooter = ({
  className,
  serverError,
  serverErrors,
  wrapperClassName,
  ...props
}: TDialogFooterProps) => {
  const parsedServerError = useMemo(() => {
    return typeof serverError === 'string' && serverError.startsWith('{') && serverError.endsWith('}')
      ? JSON.parse(serverError)
      : undefined;
  }, [serverError]);

  return (
    <div
      className={cn(
        'flex w-full flex-col-reverse items-stretch justify-between space-y-2 px-3 pb-6 pt-4 drop-shadow-xl sm:gap-2 sm:space-x-2 sm:space-y-0',
        wrapperClassName,
      )}
    >
      <div
        className={cn('flex w-full flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)}
        {...props}
      />

      {serverError ? (
        <div className="flex-1 text-end text-rose-500">
          {serverError.startsWith('{') && serverError.endsWith('}') ? (
            <Trans i18nKey={parsedServerError.key} tOptions={parsedServerError.options} />
          ) : (
            <Trans defaults={serverError} i18nKey={serverError} />
          )}
        </div>
      ) : undefined}

      {serverErrors ? (
        <div>
          {serverErrors.map((error, index) => (
            <div className="flex-1 text-end text-rose-500" key={`${index}-${error}`}>
              {error}
            </div>
          ))}
        </div>
      ) : undefined}
    </div>
  );
};

DialogFooter.displayName = 'DialogFooter';
