import { cn } from '@/_shared/utils';
import * as RSelect from '@radix-ui/react-select';
import React from 'react';

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof RSelect.Content>,
  React.ComponentPropsWithoutRef<typeof RSelect.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <RSelect.Portal>
    <RSelect.Content
      className={cn(
        'relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md animate-in fade-in-80',
        position === 'popper' && 'translate-y-1',
        className,
      )}
      position={position}
      ref={ref}
      {...props}
    >
      <RSelect.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </RSelect.Viewport>
    </RSelect.Content>
  </RSelect.Portal>
));

SelectContent.displayName = RSelect.Content.displayName;
