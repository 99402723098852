import { cn } from '@/_shared/utils';
import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';

export const NavigationMenuRoot = React.forwardRef<
  React.ElementRef<typeof RNavigationMenu.Root>,
  React.ComponentPropsWithoutRef<typeof RNavigationMenu.Root>
>(({ className, children, ...props }, ref) => (
  <RNavigationMenu.Root
    className={cn('relative z-10 flex flex-1 items-center justify-center', className)}
    ref={ref}
    {...props}
  >
    {children}
  </RNavigationMenu.Root>
));

NavigationMenuRoot.displayName = RNavigationMenu.Root.displayName;
