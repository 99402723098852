import { cn } from '@/_shared/utils';
import * as RNavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';

export const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof RNavigationMenu.Content>,
  React.ComponentPropsWithoutRef<typeof RNavigationMenu.Content>
>(({ className, ...props }, ref) => (
  <RNavigationMenu.Content
    className={cn(
      'left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right data-[motion=from-start]:slide-in-from-left data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left md:absolute md:w-auto ',
      className,
    )}
    ref={ref}
    {...props}
  />
));

NavigationMenuContent.displayName = RNavigationMenu.Content.displayName;
