import { Options } from 'ky';
import { BaseTransport } from './base.transport';

export enum ReasonType {
  AssignmentHold = 'ASSIGNMENT_HOLD',
  TaskError = 'TASK_ERROR',
}

export type TReason = {
  id: string;
  order: number;
  reason_text: string;
  type: string;
};

export type TGetReasonsRequest = {
  type?: string;
};
type TGetReasonsResponse = {
  reasons: Array<TReason>;
};

export type TUpdateReason = {
  id?: string;
  order: number;
  reason_text: string;
};

export type TUpdateTypeReasonsRequest = {
  reasons: Array<TUpdateReason>;
  type: string;
};
type TUpdateTypeReasonsResponse = void;

class InfoTransport extends BaseTransport {
  async getReasons(dto: TGetReasonsRequest, options?: Omit<Options, 'json'>): Promise<Array<TReason>> {
    const response = await this.get<TGetReasonsResponse>(
      'info/get-reasons',
      Object.assign({ searchParams: dto }, options),
    );

    return response.reasons;
  }

  async updateTypeReasons(
    dto: TUpdateTypeReasonsRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TUpdateTypeReasonsResponse> {
    return await this.post<TUpdateTypeReasonsRequest, TUpdateTypeReasonsResponse>(
      'info/update-type-reasons',
      dto,
      options,
    );
  }
}

export const infoTransport = new InfoTransport();
