import { TComboboxOption } from '@/_shared/components/organisms/combobox';
import { ExportDocumentType, StockControlReportType } from '@/_shared/enums';
import { useTranslation } from 'react-i18next';

export function useExportDocumentTypeOptions(): Array<TComboboxOption<string>> {
  const { t } = useTranslation('warehouse_stock_control');

  return [
    {
      label: t('const_inv_2', 'ИНВ-2 Приложение 5'),
      value: ExportDocumentType.EXPORT_INV2,
    },
    {
      label: t('const_simple', 'Упрощённая таблица'),
      value: ExportDocumentType.EXPORT_TABLE_SIMPLE,
    },
  ];
}

export function useExportTypeOptions(): Array<TComboboxOption<StockControlReportType>> {
  const { t } = useTranslation('warehouse_stock_control');

  return [
    {
      label: t('const_all', 'Всё'),
      value: StockControlReportType.ALL,
    },
    {
      label: t('const_more', 'Избыток'),
      value: StockControlReportType.STOCK_SURPLUS,
    },
    {
      label: t('const_less', 'Недостаток'),
      value: StockControlReportType.STOCK_LACK,
    },
    {
      label: t('const_not', 'Не проинвентаризированные товары'),
      value: StockControlReportType.ABSENT,
    },
  ];
}

export const reportLanguageTypeOptions: Array<TComboboxOption<string>> = [
  {
    label: 'Română',
    value: 'ro',
  },
  {
    label: 'Русский',
    value: 'ru',
  },
];

export enum ExportColumnKey {
  PRODUCT_CODE = 'PRODUCT_CODE',
  PRODUCT_BARCODE = 'PRODUCT_BARCODE',
  PRODUCT_NAME = 'PRODUCT_NAME',
  MEASUREMENT_UNIT = 'MEASUREMENT_UNIT',
  MU_CONVERSION_RATE = 'MU_CONVERSION_RATE',
  QUANTITY_EXPECTED = 'QUANTITY_EXPECTED',
  QUANTITY_ACTUAL = 'QUANTITY_ACTUAL',
  PRICE = 'PRICE',
  FROM_STORAGE_CELL_LABEL = 'FROM_STORAGE_CELL_LABEL',
}

export type TColumnDescription = {
  id: ExportColumnKey;
  header: string;
};

export const exportColumns: Array<TColumnDescription> = [
  { id: ExportColumnKey.PRODUCT_CODE, header: 'const_code' },
  {
    id: ExportColumnKey.PRODUCT_BARCODE,
    header: 'const_barcode',
  },
  { id: ExportColumnKey.PRODUCT_NAME, header: 'const_name' },
  { id: ExportColumnKey.MEASUREMENT_UNIT, header: 'const_unit' },
  { id: ExportColumnKey.MU_CONVERSION_RATE, header: 'const_unit_type' },
  { id: ExportColumnKey.QUANTITY_EXPECTED, header: 'const_quantity_stock' },
  { id: ExportColumnKey.QUANTITY_ACTUAL, header: 'const_quantity_fact' },
  { id: ExportColumnKey.PRICE, header: 'const_price' },
  { id: ExportColumnKey.FROM_STORAGE_CELL_LABEL, header: 'const_cell' },
];
