import { cn } from '@/_shared/utils';
import { ChevronRight } from 'lucide-react';
import React from 'react';
import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';

export const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof RDropdownMenu.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof RDropdownMenu.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => (
  <RDropdownMenu.SubTrigger
    ref={ref}
    className={cn(
      'flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent data-[state=open]:bg-accent',
      inset && 'pl-8',
      className,
    )}
    {...props}
  >
    {children}
    <ChevronRight className="ml-auto h-4 w-4" />
  </RDropdownMenu.SubTrigger>
));

DropdownMenuSubTrigger.displayName = RDropdownMenu.SubTrigger.displayName;
