import { Options } from 'ky';
import { TResponsePagination, TUser } from '../types';
import { BaseTransport } from './base.transport';

export enum OrdersUsersSortType {
  ASC_BY_CANCELED = 'ASC_BY_CANCELED',
  ASC_BY_NO_SYNC = 'ASC_BY_NO_SYNC',
  ASC_BY_PAID = 'ASC_BY_PAID',
  ASC_BY_SYNC = 'ASC_BY_SYNC',

  DESC_BY_CANCELED = 'DESC_BY_CANCELED',
  DESC_BY_NO_SYNC = 'DESC_BY_NO_SYNC',
  DESC_BY_PAID = 'DESC_BY_PAID',
  DESC_BY_SYNC = 'DESC_BY_SYNC',
}

export type TExternalReport = {
  category: string;
  id: string;
  report_url: string;
  title: string;
};

export type TMetricOrdersUsers = {
  order_canceled: number;
  order_not_sync: number;
  order_paid: number;
  order_sync: number;
  user: TUser;
};

export type TMetricOrdersPrice = {
  order_canceled_price: number;
  order_not_sync_price: number;
  order_paid_price: number;
  order_sync_price: number;
  user: TUser;
};

export type TPostMetricOrderTime = {
  order_canceled_av_time: number;
  order_not_sync_av_time: number;
  order_paid_av_time: number;
  order_sync_av_time: number;
  user: TUser;
};

export type TGetExternalReportsResponse = {
  external_reports: Array<TExternalReport>;
};

export type TFindMetricOrdersUsersRequest = {
  from_datetime?: string;
  limit_by: number;
  location_ids?: Array<string>;
  page: number;
  sort_type?: OrdersUsersSortType;
  to_datetime?: string;
  user_ids?: Array<string>;
};

export type TFindMetricOrdersUsersResponse<T> = TResponsePagination<T>;

class MetricTransport extends BaseTransport {
  async getExternalReports(options?: Omit<Options, 'json'>): Promise<Array<TExternalReport>> {
    const result = await this.get<TGetExternalReportsResponse>('metric/get-external-reports', options);

    return result.external_reports;
  }

  async findMetricByUsersWithOrders(
    dto: TFindMetricOrdersUsersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindMetricOrdersUsersResponse<TMetricOrdersUsers>> {
    return await this.post<TFindMetricOrdersUsersRequest, TFindMetricOrdersUsersResponse<TMetricOrdersUsers>>(
      'metric/store/orders-creation',
      dto,
      options,
    );
  }

  async findMetricByUsersPrice(
    dto: TFindMetricOrdersUsersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindMetricOrdersUsersResponse<TMetricOrdersPrice>> {
    return await this.post<TFindMetricOrdersUsersRequest, TFindMetricOrdersUsersResponse<TMetricOrdersPrice>>(
      'metric/store/orders-price',
      dto,
      options,
    );
  }

  async findMetricByUsersAverageTime(
    dto: TFindMetricOrdersUsersRequest,
    options?: Omit<Options, 'json'>,
  ): Promise<TFindMetricOrdersUsersResponse<TPostMetricOrderTime>> {
    return await this.post<TFindMetricOrdersUsersRequest, TFindMetricOrdersUsersResponse<TPostMetricOrderTime>>(
      'metric/store/orders-time',
      dto,
      options,
    );
  }
}

export const metricTransport = new MetricTransport();
