import { BaseTransport } from '@/_shared/transports/base.transport';
import { TUser } from '@/_shared/types';
import { Options } from 'ky';

export type TLoginResponse = {
  log_buffer_limits: unknown; // Используется только в мобильном приложении
  session_expires_at: string; // NOTE: Дата и время в формате ISO 8601
  user: TUser;
};

export type TUserResponse = {
  user: TUser;
};

export class AuthTransport extends BaseTransport {
  async login(userName: string, password: string): Promise<TLoginResponse> {
    return await this.get<TLoginResponse>('auth/login-dashboard', {
      searchParams: {
        username: userName,
        token: password,
      },
    });
  }

  async logout(): Promise<void> {
    await this.get('auth/logout');
  }

  async user(options?: Omit<Options, 'json'>): Promise<TUser> {
    const { user } = await this.get<TUserResponse>('auth/user', options);

    return user;
  }
}

export const authTransport = new AuthTransport();
