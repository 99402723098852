import * as RSelect from '@radix-ui/react-select';
import { SelectContent } from './select-content';
import { SelectInput } from './select-input';
import { SelectItem } from './select-item';
import { SelectLabel } from './select-label';
import { SelectSeparator } from './select-separator';
import { SelectTrigger } from './select-trigger';

export const Select = {
  Content: SelectContent,
  Group: RSelect.Group,
  Input: SelectInput,
  Item: SelectItem,
  Label: SelectLabel,
  Root: RSelect.Root,
  Separator: SelectSeparator,
  Trigger: SelectTrigger,
  Value: RSelect.Value,
};
