import * as RDropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuSeparator } from './dropdown-menu-separator';
import { DropdownMenuCheckboxItem } from './dropdown-menu-checkbox-item';
import { DropdownMenuContent } from './dropdown-menu-content';
import { DropdownMenuItem } from './dropdown-menu-item';
import { DropdownMenuLabel } from './dropdown-menu-label';
import { DropdownMenuShortcut } from './dropdown-menu-shortcut';
import { DropdownMenuSubContent } from './dropdown-menu-sub-content';
import { DropdownMenuSubTrigger } from './dropdown-menu-sub-trigger';

export const DropdownMenu = {
  CheckboxItem: DropdownMenuCheckboxItem,
  Content: DropdownMenuContent,
  Group: RDropdownMenu.Group,
  Item: DropdownMenuItem,
  Label: DropdownMenuLabel,
  Portal: RDropdownMenu.Portal,
  RadioGroup: RDropdownMenu.RadioGroup,
  Root: RDropdownMenu.Root,
  Separator: DropdownMenuSeparator,
  Shortcut: DropdownMenuShortcut,
  Sub: RDropdownMenu.Sub,
  SubContent: DropdownMenuSubContent,
  SubTrigger: DropdownMenuSubTrigger,
  Trigger: RDropdownMenu.Trigger,
};
