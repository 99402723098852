import { cn } from '@/_shared/utils';
import * as RToast from '@radix-ui/react-toast';
import { X } from 'lucide-react';
import * as React from 'react';

export const ToastClose = React.forwardRef<
  React.ElementRef<typeof RToast.Close>,
  React.ComponentPropsWithoutRef<typeof RToast.Close>
>(({ className, ...props }, ref) => (
  <RToast.Close
    className={cn(
      'absolute right-2 top-2 rounded-md p-1 text-slate-500 opacity-0 transition-opacity hover:text-slate-900 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100 group-[.destructive]:text-red-300 group-[.destructive]:hover:text-red-50 group-[.destructive]:focus:ring-red-400 group-[.destructive]:focus:ring-offset-red-600 dark:hover:text-slate-50',
      className,
    )}
    ref={ref}
    toast-close=""
    {...props}
  >
    <X className="h-4 w-4" />
  </RToast.Close>
));

ToastClose.displayName = RToast.Close.displayName;
