import * as RTabs from '@radix-ui/react-tabs';
import React from 'react';
import { TabsContent } from './tabs-content';
import { TabsList } from './tabs-list';
import { TabsTrigger } from './tabs-trigger';

type TTabsRootOriginalProps = React.ComponentPropsWithoutRef<typeof RTabs.Root>;
type TTabsRootProps<T extends string = string> = Omit<TTabsRootOriginalProps, 'onValueChange' | 'value'> & {
  onValueChange?: (value: T) => void;
  value?: T;
};

function TabsRoot<T extends string = string>({ children, onValueChange, ...props }: TTabsRootProps<T>): JSX.Element {
  return (
    <RTabs.Root onValueChange={onValueChange as (value: string) => void} {...props}>
      {children}
    </RTabs.Root>
  );
}

TabsRoot.displayName = RTabs.Root.displayName;

export const Tabs = {
  Content: TabsContent,
  List: TabsList,
  Root: TabsRoot,
  Trigger: TabsTrigger,
};
