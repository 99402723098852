import { createRoot } from 'react-dom/client';
import { v4 as guid } from 'uuid';
import { App } from './core/components/app';
import './index.css';
import './sentry';

window.APP_SESSION_ID = guid();

const reactRoot = createRoot(document.getElementById('root') as HTMLElement);

reactRoot.render(<App />);
