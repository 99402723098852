import { cn } from '@/_shared/utils';
import * as RAvatar from '@radix-ui/react-avatar';
import * as React from 'react';

export const AvatarImage = React.forwardRef<
  React.ElementRef<typeof RAvatar.Image>,
  React.ComponentPropsWithoutRef<typeof RAvatar.Image>
>(({ className, ...props }, ref) => (
  <RAvatar.Image className={cn('aspect-square h-full w-full', className)} ref={ref} {...props} />
));

AvatarImage.displayName = RAvatar.Image.displayName;
