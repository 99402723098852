import { FormField, Textarea } from '@/_shared/components/atoms';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { Path, useFormContext } from 'react-hook-form';
import { FormDisabledContext } from './context';

type TFormTextareaFieldProps<T extends Record<string, any>> = {
  allowUnregister?: boolean;
  className?: string;
  inputClassName?: string;
  label?: string;
  name: Path<T>;
} & Omit<React.ComponentPropsWithoutRef<typeof Textarea>, 'name'>;

export function FormTextareaField<T extends Record<string, any>>({
  allowUnregister,
  className,
  disabled,
  inputClassName,
  label,
  name,
  onBlur,
  onChange,
  ...inputProps
}: TFormTextareaFieldProps<T>) {
  const disabledContext = useContext(FormDisabledContext);
  const methods = useFormContext<T>();
  const fieldProps = methods.register(name, { disabled, onBlur, onChange, shouldUnregister: allowUnregister });
  const isInputDisabled =
    fieldProps.disabled || methods.formState.isSubmitting || methods.formState.isLoading || !!disabledContext;
  const fieldError = get(methods.formState.errors, name);
  const errorMessage = fieldError?.message as string | undefined;

  return (
    <FormField className={className} errorMessage={errorMessage} name={name} label={label}>
      <Textarea className={inputClassName} disabled={isInputDisabled} {...fieldProps} {...inputProps} />
    </FormField>
  );
}

FormTextareaField.displayName = 'FormTextField';
