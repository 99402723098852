import { cn } from '@/_shared/utils';
import React, { forwardRef } from 'react';

const inputBaseClassName =
  'flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50';

export type TInputProps = React.ComponentPropsWithoutRef<'input'>;

export const Input = forwardRef<HTMLInputElement, TInputProps>(({ className, ...props }, ref) => {
  const inputClassName = cn(inputBaseClassName, className);

  return <input className={inputClassName} ref={ref} {...props} />;
});

Input.displayName = 'Input';
