import { cn } from '@/_shared/utils';
import * as RDialog from '@radix-ui/react-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

export const DialogOverlay = forwardRef<
  ElementRef<typeof RDialog.Overlay>,
  ComponentPropsWithoutRef<typeof RDialog.Overlay>
>(({ className, ...props }, ref) => (
  <RDialog.Overlay
    className={cn(
      'fixed inset-0 z-50 bg-black/50 backdrop-blur-sm transition-all duration-100 fill-mode-forwards data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in',
      className,
    )}
    {...props}
    ref={ref}
  />
));

DialogOverlay.displayName = RDialog.Overlay.displayName;
