import { cn } from '@/_shared/utils';
import * as RAvatar from '@radix-ui/react-avatar';
import * as React from 'react';

export const AvatarRoot = React.forwardRef<
  React.ElementRef<typeof RAvatar.Root>,
  React.ComponentPropsWithoutRef<typeof RAvatar.Root>
>(({ className, ...props }, ref) => (
  <RAvatar.Root
    className={cn('relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full', className)}
    ref={ref}
    {...props}
  />
));

AvatarRoot.displayName = RAvatar.Root.displayName;
