import { cn } from '@/_shared/utils/classnames';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useMemo } from 'react';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { buttonVariants } from '../button';

function CalendarIconLeft(): JSX.Element {
  return <ChevronLeft className="h-4 w-4" />;
}

function CalendarIconRight(): JSX.Element {
  return <ChevronRight className="h-4 w-4" />;
}

export type CalendarProps = React.ComponentProps<typeof DayPicker>;
type CalenderClassNames = CalendarProps['classNames'];
type CalenderComponents = CalendarProps['components'];

export function Calendar({
  className,
  classNames,
  components,
  fixedWeeks = true,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const classNamesMemo = useMemo(
    (): CalenderClassNames => ({
      months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
      month: 'space-y-4',
      caption: 'flex justify-center pt-1 relative items-center',
      caption_label: 'text-sm font-medium',
      nav: 'space-x-1 flex items-center',
      nav_button: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'),
      nav_button_previous: 'absolute left-1',
      nav_button_next: 'absolute right-1',
      table: 'w-full border-collapse space-y-1',
      head_row: 'flex',
      head_cell: 'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
      row: 'flex w-full mt-2 justify-center',
      cell: cn(
        'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
        props.mode === 'range'
          ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
          : '[&:has([aria-selected])]:rounded-md',
      ),
      day: cn(buttonVariants({ variant: 'ghost' }), 'h-8 w-8 p-0 font-normal aria-selected:opacity-100'),
      day_range_start: 'day-range-start aria-selected:bg-slate-900 aria-selected:text-white',
      day_range_end: 'day-range-end aria-selected:bg-slate-900 aria-selected:text-white',
      day_selected: 'bg-slate-900 text-white',
      day_today: 'bg-accent text-accent-foreground',
      day_outside:
        'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
      day_disabled: 'text-muted-foreground opacity-50',
      day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
      day_hidden: 'invisible',
      ...classNames,
    }),
    [classNames],
  );

  const componentsMemo = useMemo(
    (): CalenderComponents => ({
      IconLeft: CalendarIconLeft,
      IconRight: CalendarIconRight,
      ...components,
    }),
    [components],
  );

  return (
    <DayPicker
      captionLayout="dropdown-buttons"
      fixedWeeks={fixedWeeks}
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={classNamesMemo}
      components={componentsMemo}
      {...props}
    />
  );
}

Calendar.displayName = 'Calendar';
