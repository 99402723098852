import { cn } from '@/_shared/utils';
import * as RTabs from '@radix-ui/react-tabs';
import React from 'react';

export const TabsContent = React.forwardRef<
  React.ElementRef<typeof RTabs.Content>,
  React.ComponentPropsWithoutRef<typeof RTabs.Content>
>(({ children, className, ...props }, ref) => (
  <RTabs.Content
    className={cn(
      'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className,
    )}
    ref={ref}
    tabIndex={-1}
    {...props}
  >
    {children}
  </RTabs.Content>
));

TabsContent.displayName = RTabs.Content.displayName;
